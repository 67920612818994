
import { LineStyle } from "src/app/models/line-style";
import { LocalStoreItem } from "src/app/models/local-store";
import { GeneralSettings, RouteFormatOptions, RoutePlannerOptions } from "src/app/models/map-settings";
import { MapPlaceL } from "src/app/models/mapplace";
import { PRoute } from "src/app/models/p-route";
import { PointStyle } from "src/app/models/point-style";
import { SavedPlaceCategory, SavedRouteCategory } from "src/app/models/saved-item-categories";
import { TourData } from "src/app/models/tour-data";
import { TrackPoint } from "src/app/models/track";
import { createUid } from "src/app/utils/utils";
import { MapPublicRoute, LiveTracking } from "src/dto.generated/api";

// test-user
export function localLoadTestUser() {
    const localStorage = window.localStorage;
    let isTestUser: boolean;
    const value = localStorage.getItem("t4y-maps_testuser");
    if (value && value !== "undefined") {
        const jsonItem = JSON.parse(value);
        isTestUser = jsonItem;
    }
    return isTestUser;
}
export function localStoreTestUser(isTestUser: boolean) {
    const value = JSON.stringify(isTestUser);
    localStorage.setItem("t4y-maps_testuser", value);
}
// userId
export function localLoadUserId() {
    const localStorage = window.localStorage;
    let userId: number;
    const value = localStorage.getItem("t4y-maps_id");
    if (value && value !== "undefined") {
        const jsonItem = JSON.parse(value);
        userId = jsonItem;
    }
    return userId;
}
export function localStoreUserId(userId: number) {
    const value = JSON.stringify(userId);
    localStorage.setItem("t4y-maps_id", value);
}

// tour-data
export function localStoreTourData(tourData: TourData) {
    const value = JSON.stringify(tourData);
    localStorage.setItem("t4y-maps_TourData", value);
}
export function localLoadTourData() {
    const localStorage = window.localStorage;
    let tourData = {} as TourData;
    tourData.trackPoints = Array<TrackPoint>();
    const value = localStorage.getItem("t4y-maps_TourData");
    if (value && value !== "undefined") {
        const jsonItem = JSON.parse(value);
        // console.log("localLoadTourData:jsonItem", jsonItem);
        tourData = jsonItem;
        if (tourData.tourStartTime) { tourData.tourStartTime = new Date(tourData.tourStartTime); }
        if (tourData.tourEndTime) { tourData.tourEndTime = new Date(tourData.tourEndTime); }
        if (!tourData.trackPoints) { tourData.trackPoints = Array<TrackPoint>(); }
        // for (const tp of tourData.trackPoints) {
        //     tp.timeStamp = new Date(tp.timeStamp);
        // }
    }
    // console.log("localLoadTourData-tourData", tourData);
    return tourData;
}

// general settings
export function localLoadGeneralSettings() {
    const localStorage = window.localStorage;
    let generalSettings: GeneralSettings;
    const value = localStorage.getItem("t4y-maps_generalsettings");
    if (!value) {
        generalSettings = {} as GeneralSettings;
        generalSettings.autoMapMove = true;
        generalSettings.autoMapRotation = true;
        generalSettings.showLocatorInfo = false;
        generalSettings.showMousePosition = true;
        generalSettings.useWakeLock = true;
    }
    if (value && value !== "undefined") {
        const jsonItem = JSON.parse(value);
        // console.log("utils-maps-local-storage:localLoadGeneralSettings-jsonItem", jsonItem);
        if (!jsonItem.ver) { generalSettings = jsonItem; }
        if (jsonItem.ver === "1.0") {
            generalSettings = jsonItem.data;
        }
    }
    return generalSettings;
}
export function localStoreGeneralSettings(generalSettings: GeneralSettings) {
    const item = {} as LocalStoreItem;
    item.ver = "1.0";
    item.data = generalSettings;
    const value = JSON.stringify(item);
    localStorage.setItem("t4y-maps_generalsettings", value);
}

// route-planner settings
export function localLoadRoutePlannerSettings() {
    const localStorage = window.localStorage;
    let routePlannerSettings: RoutePlannerOptions;
    const value = localStorage.getItem("t4y-maps_routeplannersettings");
    if (!value || value === "undefined") {
        routePlannerSettings = {} as RoutePlannerOptions;
        // routePlannerSettings.moveType = "ebike";
        routePlannerSettings.moveType = "";
        routePlannerSettings.avoidHighways = false;
        routePlannerSettings.avoidTollways = false;
    }
    if (value && value !== "undefined") {
        const jsonItem = JSON.parse(value);
        if (!jsonItem.ver) { routePlannerSettings = jsonItem; }
        if (jsonItem.ver === "1.0") {
            routePlannerSettings = jsonItem.data;
        }
    }
    return routePlannerSettings;
}
export function localStoreRoutePlannerSettings(routePlannerSettings: RoutePlannerOptions) {
    const item = {} as LocalStoreItem;
    item.ver = "1.0";
    item.data = routePlannerSettings;
    const value = JSON.stringify(item);
    localStorage.setItem("t4y-maps_routeplannersettings", value);
}

// route-format settings
export function localLoadRouteFormatSettings() {
    const localStorage = window.localStorage;
    let routeFormatSettings: RouteFormatOptions;
    const value = localStorage.getItem("t4y-maps_routepformatsettings");
    // console.log("localLoadRouteFormatSettings-value", value);

    if (!value || value === "undefined") {
        routeFormatSettings = {} as RouteFormatOptions;
        routeFormatSettings.drawRouteStyle = "solid";
        routeFormatSettings.drawRouteColor = "cyan";
        routeFormatSettings.drawSteepness = "T4Y";
        routeFormatSettings.drawWaypointStyle = "std";
        routeFormatSettings.drawWaypointSize = 12;
    }
    if (value && value !== "undefined") {
        const jsonItem = JSON.parse(value);
        if (jsonItem.ver === "1.0") {
            routeFormatSettings = jsonItem.data;
        }
    }
    // console.log("localLoadRouteFormatSettings-routeFormatSettings", routeFormatSettings);
    return routeFormatSettings;
}
export function localStoreRouteFormatSettings(routeFormatSettings: RouteFormatOptions) {
    const item = {} as LocalStoreItem;
    item.ver = "1.0";
    item.data = routeFormatSettings;
    const value = JSON.stringify(item);
    localStorage.setItem("t4y-maps_routepformatsettings", value);
}

// saved-places
export function localLoadSavedPlaces(userLanguage: string) {
    const localStorage = window.localStorage;
    const value = localStorage.getItem("t4y-maps_mySavedPlaces");
    // console.log("utils-maps-local-storage:localLoadSavedPlaces-mySavedPlacesItem", myFavPlacesItem);
    let mySavedPlaces: SavedPlaceCategory[];
    if (!value || value === "undefined") {
        mySavedPlaces = createDefaultSavedPlaceCategories(userLanguage);
        loadAndConvertOldFavorites(mySavedPlaces);
        localStoreSavedPlaces(mySavedPlaces);
    }
    if (value && value !== "undefined") {
        const jsonItem = JSON.parse(value);
        if (!jsonItem.ver) { mySavedPlaces = jsonItem; }
        if (jsonItem.ver === "1.0") {
            mySavedPlaces = jsonItem.data;
        }
    }
    for (const cat of mySavedPlaces) {
        if (!cat.pointStyle) {
            cat.pointStyle = {} as PointStyle;
        }
        if (!cat.pointStyle.strokeColor) {
            let color = "grey";
            if (cat.type === "$remember") { color = "red"; }
            if (cat.type === "$favorites") { color = "magenta"; }
            if (cat.type === "$tovisit") { color = "yellow"; }
            cat.pointStyle.strokeColor = color;
        }
        for (const place of cat.placeItems) {
            if (!place.id) { place.id = createUid(); }
        }
    }
    return mySavedPlaces;
}
// create dault saved-place categories
export function createDefaultSavedPlaceCategories(userLanguage: string) {
    // console.log("utils-maps-local-storage:createDefaultSavedPlaceCategories");
    const mySavedPlaces = new Array<SavedPlaceCategory>();
    let newCat: SavedPlaceCategory;
    // remember
    newCat = {} as SavedPlaceCategory;
    newCat.placeItems = new Array<MapPlaceL>();
    newCat.name = "Places to remember";
    if (userLanguage === "de") { newCat.name = "Gemerkte Orte"; }
    newCat.type = "$remember";
    newCat.visible = false;
    newCat.fixed = true;
    newCat.pointStyle = {} as PointStyle;
    mySavedPlaces.push(newCat);
    // favorites
    newCat = {} as SavedPlaceCategory;
    newCat.placeItems = new Array<MapPlaceL>();
    newCat.name = "Favorites";
    if (userLanguage === "de") { newCat.name = "Favoriten"; }
    newCat.type = "$favorites";
    newCat.visible = false;
    newCat.fixed = true;
    newCat.pointStyle = {} as PointStyle;
    mySavedPlaces.push(newCat);
    return mySavedPlaces;
}
// convert old favorites to category default
export function loadAndConvertOldFavorites(mySavedPlaces: SavedPlaceCategory[]) {
    const localStorage = window.localStorage;
    const myFavPointsItem = localStorage.getItem("t4y-maps_myFavPoints");
    // console.log("utils-maps-local-storage:loadAndConvertOldFavorites-myFlagPointsItem", myFlagPointsItem);
    if (!myFavPointsItem) { return; }
    const oldFavoritePlaces = JSON.parse(myFavPointsItem);
    let catDefault: SavedPlaceCategory;
    for (const cat of mySavedPlaces) {
        if (cat.type === "$remember") { catDefault = cat; }
    }
    // console.log("utils-maps-local-storage:loadAndConvertOldFavorites-catDefault", catDefault);
    for (const fav of oldFavoritePlaces) {
        catDefault.placeItems.push(fav);
    }
    // §todo fav - delete old storage-item
}
export function localStoreSavedPlaces(savedPlaces: SavedPlaceCategory[]) {
    const item = {} as LocalStoreItem;
    item.ver = "1.0";
    item.data = savedPlaces;
    const value = JSON.stringify(item);
    const localStorage = window.localStorage;
    localStorage.setItem("t4y-maps_mySavedPlaces", value);
}

// saved routes
export function localLoadSavedRoutes(userLanguage: string) {
    const localStorage = window.localStorage;
    const value = localStorage.getItem("t4y-maps_mySavedRoutes");
    // console.log("utils-maps-local-storage:loadLocalStorageData-value", value);
    let mySavedRoutes: SavedRouteCategory[];
    if (!value) {
        mySavedRoutes = createDefaultSavedRouteCategories(userLanguage);
        localStoreSavedRoutes(mySavedRoutes);
    }
    if (value && value !== "undefined") {
        const jsonItem = JSON.parse(value);
        // console.log("utils-maps-local-storage:loadLocalStorageData-jsonItem", jsonItem);
        if (!jsonItem.ver) { mySavedRoutes = jsonItem; }
        if (jsonItem.ver === "1.0") {
            mySavedRoutes = jsonItem.data;
        }
    }
    // repair if $remember not existent
    let ok = false;
    for (const cat of mySavedRoutes) {
        if (cat.type === "$remember") { ok = true; }
    }
    if (!ok) {
        mySavedRoutes = createDefaultSavedRouteCategories(userLanguage);
        localStoreSavedRoutes(mySavedRoutes);
    }
    // add id if not exists
    for (const cat of mySavedRoutes) {
        for (const route of cat.routeItems) {
            if (!route.routeId && route.id) { route.routeId = route.id.toString(); }
            if (!route.routeId) { route.routeId = createUid().toString(); }
        }
    }
    return mySavedRoutes;
}
export function createDefaultSavedRouteCategories(userLanguage: string) {
    // console.log("utils-maps-local-storage:createDefaultSavedPlaceCategories");
    const mySavedRoutes = new Array<SavedRouteCategory>();
    let newCat: SavedRouteCategory;
    // remember
    newCat = {} as SavedRouteCategory;
    newCat.name = "Routes to remember";
    if (userLanguage === "de") { newCat.name = "Gemerkte Routen"; }
    newCat.type = "$remember";
    newCat.visible = true;
    newCat.fixed = true;
    newCat.lineStyle = {} as LineStyle;
    newCat.lineStyle.lineColor = "#ff0000";
    newCat.routeItems = new Array<PRoute>();
    mySavedRoutes.push(newCat);
    // favorites
    newCat = {} as SavedRouteCategory;
    newCat.name = "Favorites";
    if (userLanguage === "de") { newCat.name = "Favoriten"; }
    newCat.type = "$favorite";
    newCat.visible = true;
    // newCat.fixed = true;
    newCat.lineStyle = {} as LineStyle;
    newCat.lineStyle.lineColor = "#ff00ff";
    newCat.routeItems = new Array<PRoute>();
    mySavedRoutes.push(newCat);
    return mySavedRoutes;
}
export function localStoreSavedRoutes(savedRoutes: SavedRouteCategory[]) {
    // console.log("utils-maps-local-storage:localStoreSavedRoutes-savedRoutes", savedRoutes);
    const item = {} as LocalStoreItem;
    item.ver = "1.0";
    item.data = savedRoutes;
    const value = JSON.stringify(item);
    const localStorage = window.localStorage;
    localStorage.setItem("t4y-maps_mySavedRoutes", value);
}

// live-tracking-me
export function localStoreLiveTrackingMe(liveTracking: LiveTracking) {
    const item = {} as LocalStoreItem;
    item.ver = "1.0";
    item.data = liveTracking;
    const value = JSON.stringify(item);
    const localStorage = window.localStorage;
    localStorage.setItem("t4y-maps_liveTrackingMe", value);
}
export function localLoadLiveTrackingMe(userLanguage: string) {
    const localStorage = window.localStorage;
    const value = localStorage.getItem("t4y-maps_liveTrackingMe");
    // console.log("utils-maps-local-storage:localLoadLiveTrackingMe-value", value);
    let liveTracking: LiveTracking;
    if (value && value !== "undefined") {
        const jsonItem = JSON.parse(value);
        // console.log("utils-maps-local-storage:localLoadLiveTrackings-jsonItem", jsonItem);
        if (!jsonItem.ver) { liveTracking = jsonItem; }
        if (jsonItem.ver === "1.0") {
            liveTracking = jsonItem.data;
        }
        if (liveTracking) {
            if (liveTracking.startTime) { liveTracking.startTime = new Date(liveTracking.startTime); }
            if (liveTracking.endTime) { liveTracking.endTime = new Date(liveTracking.endTime); }
        }
    }
    return liveTracking;
}

// live-trackings
export function localStoreLiveTrackings(liveTrackings: LiveTracking[]) {
    const item = {} as LocalStoreItem;
    item.ver = "1.0";
    item.data = liveTrackings;
    const value = JSON.stringify(item);
    const localStorage = window.localStorage;
    localStorage.setItem("t4y-maps_liveTrackings", value);
}
export function localLoadLiveTrackings(userLanguage: string) {
    const localStorage = window.localStorage;
    const value = localStorage.getItem("t4y-maps_liveTrackings");
    // console.log("utils-maps-local-storage:localLoadLiveTrackings-value", value);
    let liveTrackings: LiveTracking[];
    if (value && value !== "undefined") {
        const jsonItem = JSON.parse(value);
        // console.log("utils-maps-local-storage:localLoadLiveTrackings-jsonItem", jsonItem);
        if (!jsonItem.ver) { liveTrackings = jsonItem; }
        if (jsonItem.ver === "1.0") {
            liveTrackings = jsonItem.data;
        }
        for (const tracking of liveTrackings) {
            // console.log("utils-maps-local-storage:localLoadLiveTrackings-tracking", tracking);
            if (tracking.startTime) { tracking.startTime = new Date(tracking.startTime); }
            if (tracking.endTime) { tracking.endTime = new Date(tracking.endTime); }
            // console.log("utils-maps-local-storage:localLoadLiveTrackings-tracking2", tracking);
            for (const pos of tracking.trackPositions) {
                pos.at = new Date(pos.at);
            }
        }
    }
    return liveTrackings;
}

export function localStoreNotifications(notifications: any) {
    const item = {} as LocalStoreItem;
    item.ver = "1.0";
    item.data = notifications;
    const value = JSON.stringify(item);
    const localStorage = window.localStorage;
    localStorage.setItem("t4y-maps_notifications", value);
}

export function localLoadNotifications(userLanguage: string) {
    const localStorage = window.localStorage;
    const value = localStorage.getItem("t4y-maps_notifications");
    if (value && value !== "undefined") {
        const jsonItem = JSON.parse(value);
        return jsonItem.data;
    } else {
        return undefined;
    }
}

// current publish-route
export function localStoreCurrentPusblishRoute(pubRoute: MapPublicRoute) {
    const value = JSON.stringify(pubRoute);
    localStorage.setItem("t4y-maps_current-publish-route", value);
}
export function localLoadCurrentPublishRoute() {
    const localStorage = window.localStorage;
    const value = localStorage.getItem("t4y-maps_current-publish-route");
    if (value && value !== "undefined") {
        const jsonItem = JSON.parse(value);
        console.log("utils-maps-local-storage:localLoadCurrentPublishRoute-jsonItem", jsonItem);
        return jsonItem;
    } else {
        return undefined;
    }
}
export function localRemoveCurrentPublishRoute() {
    localStorageRemoveItem("t4y-maps_current-publish-route");
}

export function localStorageRemoveItem(item: string) {
    const localStorage = window.localStorage;
    localStorage.removeItem(item);
}



