<!-- publish-route-component -->
<div *ngIf="true" style="padding:0.5rem;">

    <div style="display:flex; padding:0.5rem;">
        <img style="width:30px; height:30px;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
            title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onBack()">
        <!-- title -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.PUBLISH-ROUTE.TITLE' | translate}}</span>
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- change-to-map -->
        <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
            alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onBackToMap()">
    </div>
    <hr style="margin-top:0.2rem;margin-bottom:0.5rem;">


    <div style="margin:0.5rem; margin-top:1.0rem; padding:0.5rem; background-color:honeydew">
        <div style="font-size:x-large;">
            <span>{{'MAPS.PUBLISH-ROUTE.ROUTE-TITLE' | translate}}:</span>
        </div>
        <!-- route-name -->
        <div>
            <span style="font-size:x-large;">{{pubRoute.name}}</span>
        </div>
        <!-- route-type -->
        <div>
            <span>{{routeType}}</span>
        </div>

        <!-- route-data -->
        <div style="margin-top:0.5rem;font-size:large;">
            <img style="margin-right:0.0rem;" width=16px src="./assets/icons/clock-outline.svg">
            <span> {{durationHours}}:{{durationMinutes}} </span>
            <span>&#8596; {{pubRoute.routeLength/1000 | number:'1.0-1'}} km </span>
            <span>&#10138; {{pubRoute.ascent}} m </span>
            <span>&#10136; {{pubRoute.descent}} m </span>
        </div>

    </div>

    <!-- add additional data -->
    <div style="margin:0.5rem; margin-top:1.0rem; padding:0.5rem; background-color:lemonchiffon">

        <div style="margin-top:0.5rem; font-size:large;">
            <span>{{'MAPS.PUBLISH-ROUTE.DATA-TITLE' | translate}}</span>
        </div>

        <!-- route name -->
        <div style="margin-top:1.0rem;"></div>
        <div *ngIf="isUserRouteAdmin" style="margin-right:1.0rem;">
            <span style="margin-top:1.0rem; font-size:large;">
                {{'MAPS.PUBLISH-ROUTE.DATA-NAME-TITLE' | translate}}:</span>
            <span>[de]:</span>
            <textarea mat-input class="round-rectangle" style="width:100%;" cdkTextareaAutosize cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="20" [(ngModel)]="pubRoute.nameDe" (input)="onRouteNameChange()"></textarea>
        </div>
        <div *ngIf="isUserRouteAdmin" style="margin-right:1.0rem;">
            <span style="margin-top:1.0rem; font-size:large;">
                {{'MAPS.PUBLISH-ROUTE.DATA-NAME-TITLE' | translate}}:</span>
            <span>[en]:</span>
            <textarea mat-input class="round-rectangle" style="width:100%;" cdkTextareaAutosize cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="20" [(ngModel)]="pubRoute.nameEn" (input)="onRouteNameChange()"></textarea>
        </div>


        <!-- difficulty-level -->
        <div *ngIf="true" style="margin-top:1.0rem; font-size:large;">
            <span>{{'MAPS.PUBLISH-ROUTE.DATA-DIFFICULTY-TITLE' | translate}}:</span>
        </div>
        <div style="margin-top:0.0rem;">
            <mat-radio-group [value]="difficultyLevel">
                <mat-radio-button [value]="1" (click)="onSelectDifficultyLevel(1)">
                    {{'MAPS.PUBLISH-ROUTE.DATA-DIFFICULTY-EASY_T' | translate}}</mat-radio-button>
                <mat-radio-button style="margin-left:1.0rem;" [value]="2" (click)="onSelectDifficultyLevel(2)">
                    {{'MAPS.PUBLISH-ROUTE.DATA-DIFFICULTY-MODERATE_T' | translate}}</mat-radio-button>
                <mat-radio-button style="margin-left:1.0rem;" [value]=3 (click)="onSelectDifficultyLevel(3)">
                    {{'MAPS.PUBLISH-ROUTE.DATA-DIFFICULTY-DIFFICULT_T' | translate}}</mat-radio-button>
            </mat-radio-group>
        </div>

        <!-- months -->
        <div *ngIf="true" style="margin-top:1.0rem; font-size:large;">
            <span>{{'MAPS.PUBLISH-ROUTE.DATA-MONTHS-TITLE' | translate}}:</span>
        </div>
        <div style="display:flex;">
            <div style="min-width:5rem;">
                <div *ngFor="let month of monthNames; let i=index;">
                    <ng-container *ngIf="i<3">
                        <mat-checkbox [(ngModel)]="monthChecked[i]" (change)="onMonthCheckClicked($event, i)">
                            {{month}}</mat-checkbox>
                    </ng-container>
                </div>
            </div>
            <div style="margin-left:0.5rem; min-width:5rem;">
                <div *ngFor="let month of monthNames; let i=index;">
                    <ng-container *ngIf="i>=3 && i<6">
                        <mat-checkbox [(ngModel)]="monthChecked[i]" (change)="onMonthCheckClicked($event, i)">
                            {{month}}</mat-checkbox>
                    </ng-container>
                </div>
            </div>
            <div style="margin-left:0.5rem; min-width:5rem;">
                <div *ngFor="let month of monthNames; let i=index;">
                    <ng-container *ngIf="i>=6 && i<9">
                        <mat-checkbox [(ngModel)]="monthChecked[i]" (change)="onMonthCheckClicked($event, i)">
                            {{month}}</mat-checkbox>
                    </ng-container>
                </div>
            </div>
            <div style="margin-left:0.5rem; min-width:5rem;">
                <div *ngFor="let month of monthNames; let i=index;">
                    <ng-container *ngIf="i>=9 && i<12">
                        <mat-checkbox [(ngModel)]="monthChecked[i]" (change)="onMonthCheckClicked($event, i)">
                            {{month}}</mat-checkbox>
                    </ng-container>
                </div>
            </div>
        </div>

        <!-- categories -->
        <div style="margin-top:1.0rem; font-size:large;">
            <span>{{'MAPS.PUBLISH-ROUTE.DATA-CAT-TITLE' | translate}}:</span>
        </div>
        <div style="display:flex;">
            <div style="min-width:8rem;">
                <div *ngFor="let cat of categoryNames; let i=index;">
                    <ng-container *ngIf="categoryIds[i]<100">
                        <mat-checkbox [(ngModel)]="categoryChecked[i]" (change)="onCategoryCheckClicked($event, i)">
                            {{cat}}</mat-checkbox>
                    </ng-container>
                </div>
            </div>
            <div style="margin-left:0.5rem;">
                <div *ngFor="let cat of categoryNames; let i=index;">
                    <ng-container *ngIf="categoryIds[i]>100 && categoryIds[i]<1000">
                        <mat-checkbox [(ngModel)]="categoryChecked[i]" (change)="onCategoryCheckClicked($event, i)">
                            {{cat}}</mat-checkbox>
                    </ng-container>
                </div>
            </div>
        </div>
        <div style="min-width:8rem;">
            <div *ngFor="let cat of categoryNames; let i=index;">
                <ng-container *ngIf="categoryIds[i]>1000">
                    <mat-checkbox [(ngModel)]="categoryChecked[i]" (change)="onCategoryCheckClicked($event, i)">
                        {{cat}}</mat-checkbox>
                </ng-container>
            </div>
        </div>

        <!-- route-image -->
        <div *ngIf="isUserRouteAdmin" style="margin-top:1.0rem; font-size:large;">
            <span>{{'MAPS.PUBLISH-ROUTE.DATA-IMAGE-TITLE' | translate}}:</span>
        </div>
        <div style="margin-right:1.0rem;">
            <input type="text" class="input-image" enterkeyhint="ImageId"
                placeholder="{{'MAPS.PUBLISH-ROUTE.DATA-IMAGE_INPUT_PLH' | translate}}" [(ngModel)]="pubRoute.imageIds"
                (keyup)="onRouteImageIdTextKeyUp($event)">
        </div>

        <!-- route description -->
        <div style="margin-top:1.0rem;"></div>
        <div *ngIf="languageCode === 'de' || isUserRouteAdmin" style="margin-right:1.0rem;">
            <span style="margin-top:1.0rem; font-size:large;">
                {{'MAPS.PUBLISH-ROUTE.DATA-DESCR-TITLE' | translate}}:</span>
            <span>[de]:</span>
            <textarea mat-input class="round-rectangle" style="width:100%;" cdkTextareaAutosize cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="20" [(ngModel)]="pubRoute.descriptionDe"
                (input)="onRouteDescriptionChange()"></textarea>
        </div>
        <div *ngIf="languageCode !== 'de' || isUserRouteAdmin" style="margin-right:1.0rem;">
            <span style="margin-top:1.0rem; font-size:large;">
                {{'MAPS.PUBLISH-ROUTE.DATA-DESCR-TITLE' | translate}}:</span>
            <span>[en]:</span>
            <textarea mat-input class="round-rectangle" style="width:100%;" cdkTextareaAutosize cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="20" [(ngModel)]="pubRoute.descriptionEn"
                (input)="onRouteDescriptionChange()"></textarea>
        </div>

        <!-- select a region -->
        <div *ngIf="true" style="margin-top:0.5rem; font-size:large;">
            <div style=" margin-bottom:0.5rem;">
                <span>{{'MAPS.PUBLISH-ROUTE.DATA-REGIONS-TITLE' | translate}}:</span><br>
            </div>

            <mat-form-field appearance="fill" style="width:180px;">
                <mat-label>{{'MAPS.PUBLISH-ROUTE.DATA-REGION_SELECT_LBL' | translate}}</mat-label>
                <mat-select [(ngModel)]="selectedRegionId" (change)="onRegionSelectionChange($event)">
                    <mat-option *ngFor="let country of countries" [value]="country.id"
                        (click)="onRegionSelectionChange($event)">{{country.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" style="margin-left:0.5rem; width:160px;">
                <mat-label>{{'MAPS.PUBLISH-ROUTE.DATA-REGION_SEARCH_LBL' | translate}}</mat-label>
                <input matInput id="regionInput" enterkeyhint="search" [matAutocomplete]="auto"
                    placeholder="{{'MAPS.PUBLISH-ROUTE.DATA-REGION_SEARCH_PLH' | translate}}"
                    (keyup)="onSearchRegionTextKeyUp($event)" [(ngModel)]="searchRegionText">
                <mat-autocomplete id="regionAutocomplete" #auto="matAutocomplete"
                    (optionSelected)="onFoundRegionSelected($event)">
                    <mat-option *ngFor="let region of foundRefRegions" [value]="region.name">
                        {{region.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-chip-list>
                <mat-chip *ngFor="let regionName of selectedRegionNames; let i=index"
                    (removed)="onRemoveRegionClick(i)">{{regionName}}
                    <button matChipRemove><img width=16px src="./assets/icons/close.svg"></button>
                </mat-chip>
            </mat-chip-list>
            <div style=" margin-top:0.5rem;">
                <span style="font-size:medium">{{'MAPS.PUBLISH-ROUTE.DATA-REGIONS-HELP_MSG' | translate}}</span><br>
            </div>

        </div>

    </div>


    <!-- already public message -->
    <div *ngIf="isCurrentRouteIdPublished && !isUserRouteAdmin"
        style="margin:2.0rem; padding:0.5rem; background-color:lightgrey">
        <span *ngIf="routePublishSuccessful" style="color:green">Route successfuly published!<br><br></span>
        <span *ngIf="!routePublishSuccessful" style="color:red">
            Route with Id '{{pubRoute.routeId}}' is already published!<br><br></span>
        <span style="font-size:larger">To publish a route is only possible once!</span>
    </div>

    <!-- upload to server -->
    <div *ngIf="!isCurrentRouteIdPublished" style="margin-top:1.0rem; margin-right:0.5rem; float: right;">
        <button mat-raised-button color="primary"
            (click)="onUploadRouteClick()">{{'MAPS.PUBLISH-ROUTE.UPLOAD-ROUTE_BUTTON_T' | translate}}</button>
    </div>

    <!-- update public route -->
    <div *ngIf="isUserRouteAdmin && isCurrentRoutePublic && isCurrentRouteIdPublished"
        style="margin-top:1.0rem; margin-right:0.5rem; float: right;">
        <button mat-raised-button color="primary" (click)="onUpdatePublicRouteClick()">
            {{'MAPS.PUBLISH-ROUTE.UPDATE-ROUTE_BUTTON_T' | translate}}</button>
    </div>

    <!-- approve public route -->
    <div *ngIf="isUserRouteAdmin && isCurrentRoutePublic && isCurrentRouteIdPublished && canApproveRoute"
        style="margin-top:1.0rem; margin-right:0.5rem; float: right;">
        <button mat-raised-button color="primary" (click)="onApprovePublicRouteClick()">
            {{'MAPS.PUBLISH-ROUTE.APPROVE-ROUTE_BUTTON_T' | translate}}</button>
    </div>

</div>