<!-- maps-admin-component -->
<div style="padding:0.5rem;">

    <!-- admin-title -->
    <div style="display:flex; padding:0.5rem;">
        <img style="width:30px; height:30px; cursor:pointer;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
            title="Admin" (click)="onAdminBackClick()">
        <!-- title -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <span style="margin-left:0.5rem; font-size:x-large;">Admin</span>
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- change-to-map -->
        <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
            alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onAdminBackClick()">
    </div>
    <hr style="margin-top:0.5rem;">

    <!-- functions -->
    <div style="margin:0.5rem;">

        <!-- create sitemap for public bike routes -->
        <span style="font-size:large">Create sitemaps for SEO</span>
        <div style="margin-top:0.5rem;">
            <button (click)="onCreateSitemapForBikeRoutesClick()">
                <img width="20px" src="./assets/icons/magnify.svg">Create sitemap for public bike routes
            </button><br>
            <div>
                <span>Routes with only De: {{countBikingPagesOnlyDe}}</span><br>
                <span>Routes with only En: {{countBikingPagesOnlyEn}}</span><br>
                <span>Routes with De+En: {{countBikingPagesDeEn}}</span><br>
            </div>
            <a *ngIf="sitemapNameBikeRoutes" [href]="fileUrlSitemapBikeRoutes" download="{{sitemapNameBikeRoutes}}">
                Download sitemap-file
            </a>
        </div>
        <!-- create sitemap for public hiking routes -->
        <div style="margin-top:0.5rem;">
            <button style="margin-top:0.5rem;" (click)="onCreateSitemapForHikingRoutesClick()">
                <img width="20px" src="./assets/icons/magnify.svg">Create sitemap for public hiking routes
            </button><br>
            <div>
                <span>Routes with only De: {{countHikingPagesOnlyDe}}</span><br>
                <span>Routes with only En: {{countHikingPagesOnlyEn}}</span><br>
                <span>Routes with De+En: {{countHikingPagesDeEn}}</span><br>
            </div>
            <a *ngIf="sitemapNameHikingRoutes" [href]="fileUrlSitemapHikingRoutes"
                download="{{sitemapNameHikingRoutes}}">
                Download sitemap-file
            </a>
        </div>
        <hr>

        <!-- search for a region -->
        <div style="margin-top:1.0rem;">
            <mat-form-field appearance="fill" style="min-width:150px;width:250px;">
                <mat-label>{{'MAPS.PUBLISH-ROUTE.DATA-REGION_SEARCH_LBL' | translate}}</mat-label>
                <input matInput id="regionInput" enterkeyhint="search" [matAutocomplete]="auto"
                    #trigger="matAutocompleteTrigger"
                    placeholder="{{'MAPS.PUBLISH-ROUTE.DATA-REGION_SEARCH_PLH' | translate}}"
                    (keyup)="onSearchRegionTextKeyUp($event)" [(ngModel)]="searchRegionText">
                <mat-autocomplete id="regionAutocomplete" #auto="matAutocomplete"
                    (optionSelected)="onFoundRegionSelected($event)">
                    <mat-option *ngFor="let region of foundRefRegions" [value]="region.name">
                        {{region.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <!-- route-statistics -->
        <span style="font-size:large">Public route statistics</span>
        <div style="margin-top:0.5rem;">
        </div>
        <div style="margin-top:0.5rem;">
            <button *ngIf="selectedRegionId" (click)="onExportRouteStatisticsClick()">
                <img width="20px" src="./assets/icons/magnify.svg">Export route statistics</button>
            <div *ngIf="foundPublicRoutes">
                <span>Number of routes found: {{foundPublicRoutes.length}}</span>
                <span> (in region {{selectedRegion.name}})</span>
            </div>
            <div>
                <a *ngIf="exportRouteStatistics" [href]="fileUrlRouteStatistics" download="{{exportRouteStatistics}}">
                    Download route-statistics-file
                </a>
            </div>
        </div>

        <!-- analyse public-routes -->
        <span style="font-size:large">Analyse public routes</span>
        <div style="margin-top:0.5rem;">
        </div>
        <div style="margin-top:0.5rem;">
            <mat-slide-toggle color="primary" style="margin-left:0.5rem;" [(ngModel)]="approvedRoutes"
                (change)="onApprovedRoutesChange()">
                Approved routes</mat-slide-toggle>
            <mat-slide-toggle color="primary" style="margin-left:0.5rem;" [(ngModel)]="notApprovedRoutes"
                (change)="onNotApprovedRoutesChange()">
                Not approved routes</mat-slide-toggle><br>
        </div>
        <div style="margin-top:0.5rem;">
            <button [class.selected]="analysationMode==3" style="margin-top:0.5rem;"
                (click)="onAnalysePublicRoutesClick(3)">
                <img width="20px" src="./assets/icons/magnify.svg">Pois</button>
            <button [class.selected]="analysationMode==7" style="margin-top:0.5rem;"
                (click)="onAnalysePublicRoutesClick(7)">
                <img width="20px" src="./assets/icons/magnify.svg">Image</button><br>
            <button [class.selected]="analysationMode==4" style="margin-top:1.0rem;"
                (click)="onAnalysePublicRoutesClick(4)">
                <img width="20px" src="./assets/icons/magnify.svg">Difficulty</button>
            <button [class.selected]="analysationMode==5" style="margin-top:1.0rem;"
                (click)="onAnalysePublicRoutesClick(5)">
                <img width="20px" src="./assets/icons/magnify.svg">Best months</button>
            <button [class.selected]="analysationMode==6" style="margin-top:1.0rem;"
                (click)="onAnalysePublicRoutesClick(6)">
                <img width="20px" src="./assets/icons/magnify.svg">Categories</button><br>
            <button [class.selected]="analysationMode==8" style="margin-top:1.0rem;"
                (click)="onAnalysePublicRoutesClick(8)">
                <img width="20px" src="./assets/icons/magnify.svg">Regions</button><br>
            <button [class.selected]="analysationMode==11" style="margin-top:1.0rem;"
                (click)="onAnalysePublicRoutesClick(11)">
                <img width="20px" src="./assets/icons/magnify.svg">To approve not admin</button>
            <button [class.selected]="analysationMode==12" style="margin-top:1.0rem;"
                (click)="onAnalysePublicRoutesClick(12)">
                <img width="20px" src="./assets/icons/magnify.svg">To approve</button>
        </div>
        <div style="margin-top:0.5rem;">
            <mat-slide-toggle color="primary" style="margin-left:0.5rem;" [(ngModel)]="onlyRoutesDe"
                (change)="onOnlyRoutesDeChange()">
                Only routes 'DE'</mat-slide-toggle>
            <mat-slide-toggle color="primary" style="margin-left:0.5rem;" [(ngModel)]="onlyRoutesEn"
                (change)="onOnlyRoutesEnChange()">
                Only routes 'EN'</mat-slide-toggle><br>
            <button [class.selected]="analysationMode==1" style="margin-top:0.5rem;"
                (click)="onAnalysePublicRoutesClick(1)">
                <img width="20px" src="./assets/icons/magnify.svg">Name</button>
            <button [class.selected]="analysationMode==2" style="margin-top:0.5rem;"
                (click)="onAnalysePublicRoutesClick(2)">
                <img width="20px" src="./assets/icons/magnify.svg">Description</button>
        </div>
        <div *ngIf="routeReports" style="margin-top:1.0rem;margin-left:0.5rem;">
            <ng-container *ngFor="let row of routeReports; let i=index">
                <a [href]="row" target="_blank">{{i+1}}: {{row}}</a><br>
            </ng-container>
        </div>
        <hr>

        <!-- clear notifications in local store -->
        <span style="margin-top:1.0rem; font-size:large">Notifications</span>
        <div style="margin-top:0.5rem;">
            <button class="popup-button" (click)="onRemoveNotificationsClick()">
                <img width="20px" src="./assets/icons/delete.svg">Remove notifications
            </button><br>
        </div>
        <hr>

        <!-- update-db -->
        <span style="font-size:large">Update Db</span><br>
        <div style="margin-top:0.5rem;">
            <button disabled (click)="onUpdateDbClick()">
                <img width="20px" src="./assets/icons/magnify.svg">Update Db: 1003
            </button><br>
        </div>
        <hr>

        <!-- remove auto-start in localStorage -->
        <span style="font-size:large">Remove auto-start</span><br>
        <div style="margin-top:0.5rem;">
            <button (click)="onRemoveAutoStartClick()">Remove auto-start
            </button><br>
        </div>
        <hr>

        <!-- debug-log -->
        <span style="font-size:large">Debug-log</span>
        <button style="margin-left:1.0rem;" (click)="onClearDebugLogClick()">Clear debugLog</button><br>

        <div style="margin-top:0.5rem;">
            <ul>
                <li *ngFor="let log of debugLog; let i=index">
                    {{ i+1 }} : {{ log }}
                </li>
            </ul>
        </div>

    </div>


</div>