// chart-functions

import { IScatterData, IScatterPoint } from "../models/chart-types";

export function generateElevationChartData(geojsonRoute: any) {
    // console.log("utils-charts:generateElevationChartData-geojsonRoute", geojsonRoute);
    const feature = geojsonRoute.features[0];
    const routeDistance = feature.properties.summary.distance
    let wpLast = null;
    let sumDist = 0.0;
    const elevationChartData = ({} as IScatterData);
    elevationChartData.points = new Array<IScatterPoint>();
    for (const wp of feature.geometry.coordinates) {
        if (wpLast) {
            const deltaX = wp[0] - wpLast[0];
            const deltaY = wp[1] - wpLast[1];
            const dist = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
            sumDist += dist;
            const elevation = wp[2];
            const point = {} as IScatterPoint;
            point.x = sumDist;
            point.y = elevation;
            elevationChartData.points.push(point);
        }
        wpLast = wp;
    }
    // console.log("utils-charts:generateChartData-sumDist", sumDist);
    const fact = routeDistance / sumDist;
    // console.log("utils-charts:generateChartData-fact", fact);
    for (const p of elevationChartData.points) {
        p.x = p.x * fact;
        p.x = Math.round(p.x * 1000) / 1000;
    }
    // console.log("utils-charts:generateChartData-elevationData", elevationData);
    return elevationChartData;
}
