import { MapPlaceL } from "src/app/models/mapplace";
import { MapPlace } from "src/dto.generated/api";

export function convertLPlaceToSPlace(lPlace: MapPlaceL, catId: number) {
    const place = {} as MapPlace;
    place.categoryId = catId;
    place.coordLat = lPlace.coordLat;
    place.coordLon = lPlace.coordLon;
    place.id = lPlace.id;
    // place.idOSM = lPlace.idOSM; // future
    place.description = lPlace.notice;
    place.name = lPlace.name;
    place.order = lPlace.order;
    return place;
}

export function convertSPlaceToLPlace(sPlace: MapPlace) {
    const place = {} as MapPlaceL;
    place.coordLat = sPlace.coordLat;
    place.coordLon = sPlace.coordLon;
    place.id = sPlace.id;
    // place.idOSM = sPlace.idOSM; // future
    place.notice = sPlace.description;
    place.name = sPlace.name;
    place.order = sPlace.order;
    return place;
}

export function cloneMapPlacesL(places: MapPlaceL[]) {
    const newWayPoints = new Array<MapPlaceL>();
    for (const wp of places) {
        const newPlace = cloneMapPlaceL(wp);
        newWayPoints.push(newPlace);
    }
    return newWayPoints;
}
export function cloneMapPlaceL(place: MapPlaceL) {
    const newPlace = {} as MapPlaceL;
    newPlace.coordLon = place.coordLon;
    newPlace.coordLat = place.coordLat;
    newPlace.countryCode = place.countryCode;
    newPlace.distance = place.distance;
    newPlace.id = place.id;
    newPlace.idOSM = place.idOSM;
    newPlace.imageIds = place.imageIds;
    newPlace.label = place.label;
    newPlace.layer = place.layer;
    newPlace.name = place.name;
    newPlace.notice = place.notice;
    newPlace.order = place.order;
    newPlace.type = place.type;
    newPlace.tags = place.tags;
    return newPlace;
}
