<!-- settings-component -->
<div *ngIf="true" style="padding:0.5rem;">

    <!-- settings-title -->
    <div style="display:flex; padding:0.5rem;">
        <img style="width:30px; height:30px;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
            title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onSettingsBackClick()">
        <!-- title -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.SETT.DLG.TITLE' | translate}}</span>
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- change-to-map -->
        <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
            alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onSettingsBackClick()">
    </div>
    <hr style="margin-top:0.2rem;margin-bottom:0.5rem;">

    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="start">

        <!-- general -->
        <mat-tab label="{{'MAPS.SETT.DLG.TAB-GENERAL_TITLE' | translate}}">
            <div style="padding:0.5rem;">
                <div style="margin-top:1.0rem; margin-bottom:0.5rem; font-size:x-large;">
                    {{'MAPS.SETT.DLG.DISPLAY_TITLE' | translate}}</div>
                <mat-slide-toggle color="primary" style="margin-left:0.5rem;"
                    [(ngModel)]="generalSettings.showLocatorInfo" (change)="onGeneralSettingsChanged()">
                    {{'MAPS.SETT.DLG.SHOW-LOCATOR_TOGGLE_TEXT' | translate}}
                </mat-slide-toggle><br>
                <mat-slide-toggle color="primary" style="margin-left:0.5rem;"
                    [(ngModel)]="generalSettings.showMousePosition" (change)="onGeneralSettingsChanged()">
                    {{'MAPS.SETT.DLG.SHOW-MOUSE-POSITION_TOGGLE_TEXT' | translate}}
                </mat-slide-toggle><br>
                <mat-slide-toggle color="primary" style="margin-left:0.5rem;" [(ngModel)]="generalSettings.useWakeLock"
                    (change)="onGeneralSettingsChanged()">
                    {{'MAPS.SETT.DLG.PREVENT-SLEEP-MODE_TOGGLE_TEXT' | translate}}
                </mat-slide-toggle><br>
                <mat-slide-toggle color="primary" style="margin-left:0.5rem;"
                    [(ngModel)]="generalSettings.autoMapRotation2" (change)="onGeneralSettingsChanged()">
                    {{'MAPS.SETT.DLG.AUTO-MAP-ROTATION2_TOGGLE_TEXT' | translate}}
                </mat-slide-toggle><br>

                <div style="margin-top:1.0rem; margin-bottom:0.5rem; font-size:x-large;">
                    {{'MAPS.SETT.DLG.NAVIGATION_TITLE' |
                    translate}}</div>
                <mat-slide-toggle color="primary" style="margin-left:0.5rem;" [(ngModel)]="generalSettings.autoMapMove"
                    (change)="onGeneralSettingsChanged()">
                    {{'MAPS.SETT.DLG.AUTO-MAP-MOVE_TOGGLE_TEXT' | translate}}
                </mat-slide-toggle><br>
                <mat-slide-toggle color="primary" style="margin-left:0.5rem;"
                    [(ngModel)]="generalSettings.autoMapRotation" (change)="onGeneralSettingsChanged()">
                    {{'MAPS.SETT.DLG.AUTO-MAP-ROTATION_TOGGLE_TEXT' | translate}}
                </mat-slide-toggle><br>
            </div>
            <div style="margin-top:1.0rem; margin-bottom:0.5rem; font-size:x-large;">
                {{'MAPS.SETT.DLG.RECORD-TOUR_TITLE' |
                translate}}</div>
            <!-- tracking-timer-intervall -->
            <div style="margin-top:1.0rem;">
                <span style="font-size:large">{{'MAPS.SETT.DLG.TRACKING-TIMER-INTERVALL_T' | translate}}:</span>
                <label *ngIf="generalSettings.trackingTimeIntervall<60"
                    style="margin-top:0;margin-left:1.0rem;">{{generalSettings.trackingTimeIntervall}}sec</label>
                <label *ngIf="generalSettings.trackingTimeIntervall>=60"
                    style="margin-top:0;margin-left:1.0rem;">{{generalSettings.trackingTimeIntervall/60}}min</label>
            </div>
            <!-- <span style="margin-left:0.5rem;">60 min</span> -->
            <div style="margin-top:0rem;">
                <button mat-raised-button color="primary" style="cursor:pointer;margin:0.5rem;"
                    (click)="onTourTimerIntervallClick(5)">5 sec</button>
                <button mat-raised-button color="primary" style="cursor:pointer;margin:0.5rem;"
                    (click)="onTourTimerIntervallClick(10)">10 sec</button>
                <button mat-raised-button color="primary" style="cursor:pointer;margin:0.5rem;"
                    (click)="onTourTimerIntervallClick(30)">30 sec</button>
                <button mat-raised-button color="primary" style="cursor:pointer;margin:0.5rem;"
                    (click)="onTourTimerIntervallClick(60)">1 min</button>
                <button mat-raised-button color="primary" style="cursor:pointer;margin:0.5rem;"
                    (click)="onTourTimerIntervallClick(300)">5 min</button>
                <button mat-raised-button color="primary" style="cursor:pointer;margin:0.5rem;"
                    (click)="onTourTimerIntervallClick(600)">10 min</button>
            </div>


            <button *ngIf="generalSettingsChanged" mat-raised-button color="primary"
                style="cursor:pointer;margin:0.5rem;" (click)="onSaveGeneralSettingsClick()">
                <img width="20px" src="./assets/icons/content-save-cog-outline.svg" alt="symbol">
                {{'MAPS.SETT.DLG.MAKE-GEN-PERMANENT_BUTT_TEXT' | translate}}</button>

        </mat-tab>

        <!-- route-planning -->
        <mat-tab label="{{'MAPS.SETT.DLG.TAB-RPL_TITLE' | translate}}">
            <div style="padding:0.5rem;">
                <div style="margin-top:1.0rem; margin-left:0.5rem; margin-bottom:0.5rem; font-size:x-large;">
                    {{'MAPS.SETT.DLG.RPL-MOVE-TYPE_TITLE' | translate}}</div>
                <!-- default movetype -->
                <div>
                    <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                        [style.background-color]="routePlannerSettings.moveType==='bike' ? 'greenyellow' : ''"
                        src="./assets/icons/bike.svg" title="{{'MAPS.SETT.DLG.BYBIKE_T' | translate}}" alt="symbol"
                        (click)="onRoutePlannerMoveTypeChange('bike')">
                    <span style="margin-left:1.0rem;">{{'MAPS.SETT.DLG.BYBIKE_T' | translate}}</span><br>
                    <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                        [style.background-color]="routePlannerSettings.moveType==='ebike' ? 'greenyellow' : ''"
                        src="./assets/icons/bicycle-electric.svg" title="{{'MAPS.SETT.DLG.BYEBIKE_T' | translate}}"
                        alt="symbol" (click)="onRoutePlannerMoveTypeChange('ebike')">
                    <span style="margin-left:1.0rem;">{{'MAPS.SETT.DLG.BYEBIKE_T' | translate}}</span><br>
                    <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                        [style.background-color]="routePlannerSettings.moveType==='bike-road' ? 'greenyellow' : ''"
                        src="./assets/icons/bike-road.svg" title="{{'MAPS.SETT.DLG.BYBIKEROAD_T' | translate}}"
                        alt="symbol" (click)="onRoutePlannerMoveTypeChange('bike-road')">
                    <span style="margin-left:1.0rem;">{{'MAPS.SETT.DLG.BYBIKEROAD_T' | translate}}</span><br>
                    <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                        [style.background-color]="routePlannerSettings.moveType==='mtb' ? 'greenyellow' : ''"
                        src="./assets/icons/bike-mtb.svg" title="{{'MAPS.SETT.DLG.BYMTB_T' | translate}}" alt="symbol"
                        (click)="onRoutePlannerMoveTypeChange('mtb')">
                    <span style="margin-left:1.0rem;">{{'MAPS.SETT.DLG.BYMTB_T' | translate}}</span><br>
                    <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                        [style.background-color]="routePlannerSettings.moveType==='walking' ? 'greenyellow' : ''"
                        src="./assets/icons/walking.svg" title="{{'MAPS.SETT.DLG.WALKING_T' | translate}}" alt="symbol"
                        (click)="onRoutePlannerMoveTypeChange('walking')">
                    <span style="margin-left:1.0rem;">{{'MAPS.SETT.DLG.WALKING_T' | translate}}</span><br>
                    <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                        [style.background-color]="routePlannerSettings.moveType==='hiking' ? 'greenyellow' : ''"
                        src="./assets/icons/hiking.svg" width="24px" title="{{'MAPS.SETT.DLG.HIKING_T' | translate}}"
                        alt="symbol" (click)="onRoutePlannerMoveTypeChange('hiking')">
                    <span style="margin-left:1.0rem;">{{'MAPS.SETT.DLG.HIKING_T' | translate}}</span><br>
                    <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                        [style.background-color]="routePlannerSettings.moveType==='car' ? 'greenyellow' : ''"
                        src="./assets/icons/car-hatchback.svg" title="{{'MAPS.SETT.DLG.BYCAR_T' | translate}}"
                        alt="symbol" (click)="onRoutePlannerMoveTypeChange('car')">
                    <span style="margin-left:1.0rem;">{{'MAPS.SETT.DLG.BYCAR_T' | translate}}</span><br>
                </div>
            </div>
            <button *ngIf="routePlannerSettingsChanged" mat-raised-button color="primary"
                style="cursor:pointer; margin:0.5rem;" (click)="onSaveRoutePlannerSettingsClick()">
                <img width="20px" src="./assets/icons/content-save-cog-outline.svg" alt="symbol">
                {{'MAPS.SETT.DLG.MAKE-RPL-PERMANENT_BUTT_TEXT' | translate}}</button>

            <div style="padding:0.5rem;">
                <div style="padding:0.5rem;">
                    <div style="margin-top:1.0rem; margin-bottom:0.5rem; font-size:x-large;">
                        {{'MAPS.SETT.DLG.ROUTE-FORMAT_TITLE' | translate}}</div>
                    <mat-slide-toggle color="primary" style="margin-left:0.5rem;" [(ngModel)]="routeFormatSteepnessORS"
                        (change)="onRouteFormatSettingsORSChange()">
                        {{'MAPS.SETT.DLG.SHOW-STEEPNESS-ORS_TOGGLE_TEXT' | translate}}
                    </mat-slide-toggle><br>
                    <mat-slide-toggle color="primary" style="margin-left:0.5rem;" [(ngModel)]="routeFormatSteepnessT4Y"
                        (change)="onRouteFormatSettingsT4YChange()">
                        {{'MAPS.SETT.DLG.SHOW-STEEPNESS-T4Y_TOGGLE_TEXT' | translate}}
                    </mat-slide-toggle><br>
                </div>
            </div>
            <button *ngIf="routeFormatSettingsChanged" mat-raised-button color="primary"
                style="cursor:pointer; margin:0.5rem;" (click)="onSaveRouteFormatSettingsClick()">
                <img width="20px" src="./assets/icons/content-save-cog-outline.svg" alt="symbol">
                {{'MAPS.SETT.DLG.MAKE-RPL-PERMANENT_BUTT_TEXT' | translate}}</button>

        </mat-tab>

    </mat-tab-group>

</div>