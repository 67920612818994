<!-- title -->
<div style="display:flex; padding:0.5rem;">
  <img style="width:30px; height:30px;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
    title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onBack()">
  <!-- title -->
  <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
  <div *ngIf="currentGeojsonRoute">
    <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.ROUTE-DET.TITLE' | translate}} {{routeName}}</span>
  </div>
  <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
  <!-- change-to-map -->
  <img style="cursor:pointer; margin-right:1.0rem;" width=36px src="./assets/icons/home-map-marker.svg" alt="symbol"
    title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onBackToMap()">
</div>
<hr style="margin-top:0.2rem;margin-bottom:0.5rem;">

<!-- show route -->
<div *ngIf="currentGeojsonRoute" style="margin:0.5rem;">
  <!-- statistics -->
  <div style="margin-right:1.0rem; background-color: floralwhite; font-size:large; padding:0.5rem;">
    <span style="margin-top:1.0em; margin-bottom:0.5rem;">
      {{'MAPS.ROUTE-DET.MOVEMENT-TYPE_LBL' | translate}}:</span>
    <span *ngIf="moveType ==='cycling-regular'" style="margin-left:0.5rem;">{{'MAPS.ROUTE-DET.BYBIKE_T' |
      translate}}</span>
    <span *ngIf="moveType ==='cycling-electric'" style="margin-left:0.5rem;">{{'MAPS.ROUTE-DET.BYEBIKE_T' |
      translate}}</span>
    <span *ngIf="moveType ==='cycling-road'" style="margin-left:0.5rem;">{{'MAPS.ROUTE-DET.BYBIKEROAD_T' |
      translate}}</span>
    <span *ngIf="moveType ==='cycling-mountain'" style="margin-left:0.5rem;">{{'MAPS.ROUTE-DET.BYMTB_T' |
      translate}}</span>
    <span *ngIf="moveType ==='foot-walking'" style="margin-left:0.5rem;">{{'MAPS.ROUTE-DET.WALKING_T' |
      translate}}</span>
    <span *ngIf="moveType ==='foot-hiking'" style="margin-left:0.5rem;">{{'MAPS.ROUTE-DET.HIKING_T' | translate}}</span>
    <span *ngIf="moveType ==='driving-car'" style="margin-left:0.5rem;">{{'MAPS.ROUTE-DET.BYCAR_T' | translate}}</span>
    <br>
    <span>&#8596; {{'MAPS.ROUTE-DET.DISTANCE_LBL' | translate}}: {{routeDistance | number: '1.0-3'}} km</span><br>
    <span><img style="margin-right:0.0rem;" width=16px src="./assets/icons/clock-outline.svg">
      {{'MAPS.ROUTE-DET.DURATION_LBL' | translate}}: {{routeDurationHours}}:{{routeDurationMinutes}}</span><br>
    <span>&#216; {{'MAPS.ROUTE-DET.AVERAGE-SPEED_LBL' | translate}}:
      {{routeDistance / (routeDuration / 60) | number: '1.0-1'}} km/h</span><br>
    <span>&#10138; {{'MAPS.ROUTE-DET.ACENT_LBL' | translate}}: {{routeAscent}} m</span><br>
    <span>&#10136; {{'MAPS.ROUTE-DET.DECENT_LBL' | translate}}: {{routeDescent}} m</span><br>
    <span>&#9662; {{'MAPS.ROUTE-DET.MIN-ELE_LBL' | translate}}: {{minElevation}} m</span><br>
    <span>&#9652; {{'MAPS.ROUTE-DET.MAX-ELE_LBL' | translate}}: {{maxElevation}} m</span><br>
  </div>

  <!-- elevation-profile -->
  <div style="margin:0.5rem; margin-top:1.0rem; font-size:x-large;">{{'MAPS.ROUTE-DET.ELE-PROFILE_TITLE' | translate}}
  </div>
  <div style="margin:0.5rem; margin-right:1.0rem;">
    <app-elevation-profile-chart-component [data]="elevationChartData" [chartId]="'elev0-chart'"
      [languageCode]="languageCode" (pointedIndexChanged)="onPointedIndexChanged($event)">
    </app-elevation-profile-chart-component>
  </div>

  <!-- surface-legend -->
  <div *ngIf="surfaceChartData" style="margin:0.5rem;">
    <span style="font-size:x-large;">{{'MAPS.ROUTE-DET.SURFACE-TYPES_TITLE' | translate}}</span>
    <div style="margin-top:0.5rem; display:flex">
      <div>
        <div *ngFor="let summary of surfaceSummaries">
          <hr size="4px" width="50px" noshade style="margin:13px;margin-left:0;"
            [color]="getSurfaceColor(summary.value)">
        </div>
      </div>
      <div>
        <div *ngFor="let summary of surfaceSummaries">
          <span style="margin-left:0.5rem;">{{getSurfaceName(summary.value)}}:</span>
        </div>
      </div>
      <div>
        <div *ngFor="let summary of surfaceSummaries" style="text-align:right;">
          <span style="margin-left:0.5rem;">{{summary.distance | number: '1.1-1'}}m</span>
        </div>
      </div>
    </div>
  </div>
  <!-- surface-profile -->
  <div *ngIf="surfaceChartData && !showSurfaceProfile">
    <button mat-raised-button color="primary" (click)="showSurfaceProfile=true">{{'MAPS.SURFACE-CHART.SHOW_TEXT' |
      translate}}</button>
  </div>
  <div *ngIf="showSurfaceProfile" style="margin:0.5rem; margin-right:1.0rem;">
    <app-surface-profile-chart-component [data]="elevationChartData" [surfaceSections]="surfaceChartData"
      [languageCode]="languageCode">
    </app-surface-profile-chart-component>
  </div>

  <!-- waytype-legend -->
  <div *ngIf="waytypeChartData" style="margin:0.5rem;">
    <span style="font-size:x-large;">{{'MAPS.ROUTE-DET.WAY-TYPES_TITLE' | translate}}</span>
    <div style="margin-top:0.5rem; display:flex">
      <div>
        <div *ngFor="let summary of waytypeSummaries">
          <hr size="4px" width="50px" noshade style="margin:13px;margin-left:0;"
            [color]="getWaytypeColor(summary.value)">
        </div>
      </div>
      <div>
        <div *ngFor="let summary of waytypeSummaries">
          <span style="margin-left:0.5rem;">{{getWaytypeName(summary.value)}}:</span>
        </div>
      </div>
      <div>
        <div *ngFor="let summary of waytypeSummaries" style="text-align:right;">
          <span style="margin-left:0.5rem;">{{summary.distance | number: '1.1-1'}}m</span>
        </div>
      </div>
    </div>
  </div>
  <!-- waytype-profile -->
  <div *ngIf="waytypeChartData && !showWaytypeProfile">
    <button mat-raised-button color="primary" (click)="showWaytypeProfile=true">{{'MAPS.WAYTYPE-CHART.SHOW_TEXT' |
      translate}}</button>
  </div>
  <div *ngIf="showWaytypeProfile" style="margin:0.5rem; margin-right:1.0rem;">
    <app-waytype-profile-chart-component [data]="elevationChartData" [waytypeSections]="waytypeChartData"
      [languageCode]="languageCode">
    </app-waytype-profile-chart-component>
  </div>

  <!-- steepness-legend -->
  <div *ngIf="steepnessSummaries" style="margin:0.5rem;">
    <span style="font-size:x-large;">{{'MAPS.ROUTE-DET.INCLINE-CATEGORY_TITLE' | translate}}</span>
    <div style="margin-top:0.5rem; display:flex">
      <div>
        <div *ngFor="let summary of steepnessSummaries">
          <ng-container *ngIf="summary.value >=0">
            <hr size="4px" width="50px" noshade style="margin:13px;margin-left:0;"
              [color]="getSteepnessColor(summary.value)">
          </ng-container>
        </div>
      </div>
      <div>
        <div *ngFor="let summary of steepnessSummaries">
          <span *ngIf="summary.value >=0" style="margin-left:0.5rem;">{{getSteepnessName(summary.value)}}</span>
        </div>
      </div>
      <div>
        <div *ngFor="let summary of steepnessSummaries" style="text-align:right;">
          <span *ngIf="summary.value >=0" style="margin-left:0.5rem;">{{summary.distance | number: '1.1-1'}}m</span>
        </div>
      </div>
    </div>
    <span style="font-size:x-large;">{{'MAPS.ROUTE-DET.DECLINE-CATEGORY_TITLE' | translate}}</span>
    <div style="margin-top:0.5rem; display:flex">
      <div>
        <div *ngFor="let summary of steepnessSummaries">
          <ng-container *ngIf="summary.value <0">
            <hr size="4px" width="50px" noshade style="margin:13px;margin-left:0;"
              [color]="getSteepnessColor(summary.value)">
          </ng-container>
        </div>
      </div>
      <div>
        <div *ngFor="let summary of steepnessSummaries">
          <span *ngIf="summary.value <0" style="margin-left:0.5rem;">{{getSteepnessName(summary.value)}}</span>
        </div>
      </div>
      <div>
        <div *ngFor="let summary of steepnessSummaries" style="text-align:right;">
          <span *ngIf="summary.value <0" style="margin-left:0.5rem;">{{summary.distance | number: '1.1-1'}}m</span>
        </div>
      </div>
    </div>
  </div>
  <!-- steepness-profile -->
  <ng-container *ngIf="steepnessChartData">
    <div *ngIf="!showSteepnessProfile">
      <button mat-raised-button color="primary" (click)="showSteepnessProfile=true">{{'MAPS.STEEPNESS-CHART.SHOW_TEXT' |
        translate}}</button>
    </div>
    <div *ngIf="showSteepnessProfile" style="margin:0.5rem; margin-right:1.0rem;">
      <app-steepness-profile-chart-component [data]="elevationChartData" [steepnessSections]="steepnessChartData"
        [languageCode]="languageCode">
      </app-steepness-profile-chart-component>
    </div>
  </ng-container>

  <!-- details -->
  <div style="margin:0.5rem; margin-right:1.0rem; font-size:medium; background-color:floralwhite; padding:0.5rem;">
    <span style="font-size:x-large">{{'MAPS.ROUTE-DET.DETAILS_TITLE' | translate}}</span><br>
    <!-- <span>Number of segments: {{feature.properties.segments.length}}</span><br> -->
    <div *ngFor="let segment of feature.properties.segments; let i = index">
      <span style="font-size:x-large">{{'MAPS.ROUTE-DET.SEGMENT_LBL' | translate}} {{i+1}}:</span>
      <div *ngFor="let step of feature.properties.segments[i].steps; let j = index">
        <span>{{'MAPS.ROUTE-DET.STEP_LBL' | translate}} {{j+1}}: {{step.distance}} m {{step.instruction}}</span>
      </div>
    </div>
  </div>

</div>