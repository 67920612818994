import { Component, OnInit, OnDestroy, AfterViewInit, Input, EventEmitter, Output, ViewChild, ElementRef } from "@angular/core";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { localStorageRemoveItem } from "../maps-utils/utils-maps-local-storage";
import { GlobalService } from "src/app/services/global.service";
import { MapSearchRouteFilter } from "src/app/models/search-route-filter";
import { DomSanitizer, SafeValue } from "@angular/platform-browser";
import { EnumGlobalStatusCode, MapPublicRoute, RefRegion } from "src/dto.generated/api";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { convertPubRouteToLRoute } from "../maps-utils/utils-route";


@Component({
  selector: "app-maps-admin-component",
  templateUrl: "./maps-admin.component.html",
  styleUrls: ["./maps-admin.component.scss"]
})
export class MapsAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() languageCode: string;
  @Input() debugLog: string[];

  @Output() close: EventEmitter<void> = new EventEmitter();

  public sitemapNameBikeRoutes: string;
  public fileUrlSitemapBikeRoutes: SafeValue;
  public sitemapNameHikingRoutes: string;
  public fileUrlSitemapHikingRoutes: SafeValue;

  public searchRegionText: string;
  public foundRefRegions: RefRegion[];
  public selectedRegion: RefRegion;
  public selectedRegionId: number;

  public exportRouteStatistics: string;
  public fileUrlRouteStatistics: SafeValue;
  public foundPublicRoutes: MapPublicRoute[];

  public analysationMode: number;
  public routeReports: string[];
  public approvedRoutes = true;
  public notApprovedRoutes = false;
  public onlyRoutesDe: boolean;
  public onlyRoutesEn: boolean;

  public countHikingPagesOnlyDe: number;
  public countHikingPagesOnlyEn: number;
  public countHikingPagesDeEn: number;
  public countBikingPagesOnlyDe: number;
  public countBikingPagesOnlyEn: number;
  public countBikingPagesDeEn: number;

  private countPagesOnlyDe: number;
  private countPagesOnlyEn: number;
  private countPagesDeEn: number;

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private globalService: GlobalService,
    private sanitizer: DomSanitizer,
  ) { }

  public async ngOnInit() {
    console.log("Admin:ngOnInit");
  }
  public ngAfterViewInit() {
    setTimeout(() => { this.scrollToDlg(); });
  }
  public ngOnDestroy(): void {
  }

  private scrollToDlg() {
  }

  public onAdminBackClick() {
    this.close.emit();
  }

  public onRemoveNotificationsClick() {
    localStorageRemoveItem("t4y-maps_notifications");
  }


  public async onCreateSitemapForBikeRoutesClick() {
    console.log("Admin:onCreateSitemapForBikeRoutesClick");
    const moveType = "bike";
    this.fileUrlSitemapBikeRoutes = await this.createSitemap(moveType);
    this.sitemapNameBikeRoutes = "sitemap_bike_routes.xml";
    this.countBikingPagesOnlyDe = this.countPagesOnlyDe;
    this.countBikingPagesOnlyEn = this.countPagesOnlyEn;
    this.countBikingPagesDeEn = this.countPagesDeEn;
  }
  public async onCreateSitemapForHikingRoutesClick() {
    console.log("Admin:onCreateSitemapForHikingRoutesClick");
    const moveType = "hiking";
    this.fileUrlSitemapHikingRoutes = await this.createSitemap(moveType);
    this.sitemapNameHikingRoutes = "sitemap_hiking_routes.xml";
    this.countHikingPagesOnlyDe = this.countPagesOnlyDe;
    this.countHikingPagesOnlyEn = this.countPagesOnlyEn;
    this.countHikingPagesDeEn = this.countPagesDeEn;
  }

  private async createSitemap(moveType: string) {
    const strNL = "\r\n";
    const strBL2 = "  ";
    const strBL4 = "    ";
    const strBL6 = "      ";
    const searchFilter = {} as MapSearchRouteFilter;
    searchFilter.moveType = moveType;
    searchFilter.textInName = "";
    searchFilter.categories = new Array<string>();
    searchFilter.maxRouteLength = 1000000.0;
    this.countPagesOnlyDe = 0;
    this.countPagesOnlyEn = 0;
    this.countPagesDeEn = 0;
    const result = await this.globalService.searchPublicRoutesByFilter(searchFilter, false, 1);
    console.log("Admin:createSiteMap-result", result);
    let data = "";
    data += '<?xml version="1.0" encoding="UTF-8"?>' + strNL;
    data += strNL;
    data += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xhtml="http://www.w3.org/1999/xhtml">' + strNL;

    data += strNL;
    data += strBL4 + "<!-- " + moveType + " route pages -->" + strNL;
    const routes = result.routes;
    for (const route of routes) {
      if (!route.approved) { continue; }
      // console.log("Admin:createSiteMap-routeName", route.name);
      let langDe: string;
      if (route.nameDe && route.nameDe !== "") langDe = "de";
      let langEn: string;
      if (route.nameEn && route.nameEn !== "") langEn = "en";
      if (langDe && !langEn) { this.countPagesOnlyDe++; }
      if (!langDe && langEn) { this.countPagesOnlyEn++; }
      if (langDe && langEn) { this.countPagesDeEn++; }
      console.log("Admin:onCreateSitemap-langDe", langDe);
      console.log("Admin:onCreateSitemap-langEn", langEn);
      console.log("Admin:createSiteMap-countPagesOnlyDe", this.countPagesOnlyDe);
      if (langDe) {
        data += strBL4 + "<!-- route:" + route.nameDe + " -->" + strNL;
        data += strBL4 + "<url>" + strNL;
        data += strBL6 + "<loc>https://maps.trip4you.net/route/de/" + route.routeId + "</loc>" + strNL;
        // if (!langEn) {
        //   data += strBL6 + "<xhtml:link hreflang='de'/>" + strNL;
        // }
        if (langEn) {
          data += strBL6 + '<xhtml:link rel="canonical" hreflang="de" href="https://maps.trip4you.net/route/de/' + route.routeId + '"/>' + strNL;
          data += strBL6 + '<xhtml:link rel="alternate" hreflang="en" href="https://maps.trip4you.net/route/en/' + route.routeId + '"/>' + strNL;
          data += strBL6 + '<xhtml:link rel="alternate" hreflang="x-default" href="https://maps.trip4you.net/route/en/' + route.routeId + '"/>' + strNL;
        }
        data += strBL4 + "</url> " + strNL;
      }
      if (langEn) {
        data += strBL4 + "<!-- route:" + route.nameEn + " -->" + strNL;
        data += strBL4 + "<url>" + strNL;
        data += strBL6 + "<loc>https://maps.trip4you.net/route/en/" + route.routeId + "</loc>" + strNL;
        // if (!langDe) {
        //   data += strBL6 + "<xhtml:link hreflang='en'/>" + strNL;
        // }
        if (langDe) {
          data += strBL6 + '<xhtml:link rel="canonical" hreflang="en" href="https://maps.trip4you.net/route/en/' + route.routeId + '"/>' + strNL;
          data += strBL6 + '<xhtml:link rel="alternate" hreflang="de" href="https://maps.trip4you.net/route/de/' + route.routeId + '"/>' + strNL;
          data += strBL6 + '<xhtml:link rel="alternate" hreflang="x-default" href="https://maps.trip4you.net/route/en/' + route.routeId + '"/>' + strNL;
        }
        data += strBL4 + "</url> " + strNL;
      }
    }
    data += strNL;
    data += "</urlset>";
    console.log("Admin:createSiteMap-data", data);
    // for download
    const blob = new Blob([data], { type: "application/octet-stream" });
    console.log("Admin:createSiteMap-blob", blob);
    const objUrl = window.URL.createObjectURL(blob);
    console.log("Admin:createSiteMap-objUrl", objUrl);
    const fileUrlSitemap = this.sanitizer.bypassSecurityTrustResourceUrl(objUrl);
    return fileUrlSitemap;
  }

  public async onAnalysePublicRoutesClick(mode: number) {
    // console.log("Admin:onAnalysePublicRoutesClick");
    this.routeReports = undefined;
    this.analysationMode = mode;
    let regionId = 0;
    if (this.selectedRegionId) { regionId = this.selectedRegionId; }
    const result = await this.globalService.analysePublicRoutes(mode, regionId, this.approvedRoutes, this.notApprovedRoutes);
    if (result.status != EnumGlobalStatusCode.Success) { return; }
    if (mode === 1 || mode === 2) {
      this.routeReports = new Array<string>();
      for (const rep of result.reports) {
        // console.log("Admin:onAnalysePublicRoutesClick-rep", rep);
        let add = true;
        if (this.onlyRoutesDe && !rep.includes("/de/")) { add = false; }
        if (this.onlyRoutesEn && !rep.includes("/en/")) { add = false; }
        if (add) { this.routeReports.push(rep); }
      }
    } else {
      this.routeReports = result.reports;
    }
  }

  public async onExportRouteStatisticsClick() {
    this.exportRouteStatistics = undefined;
    this.foundPublicRoutes = undefined;
    this.fileUrlRouteStatistics = await this.createRouteStatistics();
    const now = new Date(Date.now());
    let strMonth = now.getMonth().toString();
    if (strMonth.length === 1) { strMonth = "0" + strMonth; }
    let strDay = now.getDate().toString();
    if (strDay.length === 1) { strDay = "0" + strDay; }
    const strDate = now.getFullYear() + "-" + strMonth + "-" + strDay;
    this.exportRouteStatistics = "routes_" + this.searchRegionText + "_" + strDate + ".csv";

  }
  public async createRouteStatistics() {
    const searchFilter = {} as MapSearchRouteFilter;
    searchFilter.textInName = "";
    searchFilter.categories = new Array<string>();
    searchFilter.maxRouteLength = 1000000.0;
    searchFilter.regionId = this.selectedRegionId;
    const result = await this.globalService.searchPublicRoutesByFilter(searchFilter, false, -1);
    // console.log("Admin:createRouteStatistics-result", result);
    if (result.status != EnumGlobalStatusCode.Success) { return; }
    this.foundPublicRoutes = result.routes;
    const strNL = "\r\n";
    const strSEMI = ";";
    let data = "";
    data += 'DbId;RouteId;MoveType;Approved;NameDe;NameEn;DescDe;DescEn;RegionIds;ImageId;' + strNL;
    for (const pubRoute of this.foundPublicRoutes) {
      const routeL = convertPubRouteToLRoute(pubRoute);
      const moveType = routeL.plannerOptions.moveType;
      const nameDe = routeL.nameDe ? routeL.nameDe : "";
      const nameEn = routeL.nameEn ? routeL.nameEn : "";
      const descDe = routeL.descriptionDe ? routeL.descriptionDe.length : 0;
      const descEn = routeL.descriptionEn ? routeL.descriptionEn.length : 0;
      const regionIds = this.sortRegionIds(pubRoute.regionIds);
      const imageIds = pubRoute.imageIds ? pubRoute.imageIds : "0";
      data += routeL.id + strSEMI + routeL.routeId + strSEMI + moveType + strSEMI + pubRoute.approved + strSEMI +
        nameDe + strSEMI + nameEn + strSEMI + descDe + strSEMI + descEn + strSEMI + regionIds + strSEMI + imageIds + strNL;
    }
    // console.log("Admin:createRouteStatistics-data", data);
    // for download
    const blob = new Blob([data], { type: "text/csv;charset=utf-16;" });
    // console.log("Admin:createRouteStatistics-blob", blob);
    const objUrl = URL.createObjectURL(blob);
    // console.log("Admin:createRouteStatistics-objUrl", objUrl);
    // const fileUrlRouteStatistics = this.sanitizer.bypassSecurityTrustResourceUrl(objUrl);
    // return fileUrlRouteStatistics;
    return objUrl;
  }

  private sortRegionIds(regionIdsString: string) {
    const regionIds = new Array<number>();
    const items = regionIdsString.split(",");
    for (const item of items) {
      if (item.length === 0) { continue; }
      const idString = item.slice(1, item.length - 1);
      const id = Number(idString);
      regionIds.push(id);
    }
    const sortedRegionIds = regionIds.sort((a, b) => a - b);
    // console.log("Admin:createRouteStatistics-sortedRegionIds", sortedRegionIds);
    let sortedRegionIdsString = "";
    for (const id of sortedRegionIds) {
      sortedRegionIdsString += id.toString() + "/";
    }
    return sortedRegionIdsString;
  }

  public onOnlyRoutesDeChange() {
    if (this.onlyRoutesDe) { this.onlyRoutesEn = false; }
  }
  public onOnlyRoutesEnChange() {
    if (this.onlyRoutesEn) { this.onlyRoutesDe = false; }
  }
  public onApprovedRoutesChange() {
    if (!this.approvedRoutes) { this.notApprovedRoutes = true; }
  }
  public onNotApprovedRoutesChange() {
    if (!this.notApprovedRoutes) { this.approvedRoutes = true; }
  }

  public async onUpdateDbClick() {
    console.log("Admin:onUpdateDbClick");
    const ok = await this.globalService.updateDb(1003);  // update map-attributes of user-table
  }

  public async onSearchRegionTextKeyUp(event: KeyboardEvent) {
    // console.log("SearchRoute:onSearchRegionTextKeyUp", event);
    if (event.key === "Enter") {
      // console.log("SearchRoute:onSearchRegionTextKeyUp-searchRegionText", this.searchRegionText);
      const refRegion = this.findRefRegionInFoundList(this.searchRegionText);
      // const refRegion = this.getSelectedRefRegion(this.searchRegionText);
      console.log("SearchRoute:onSearchRegionTextKeyUp-refRegion", refRegion);
      if (!refRegion) return;
      this.searchRegionText = refRegion.name;
      this.selectedRegion = refRegion;
      this.selectedRegionId = refRegion.id;
      this.autocompleteTrigger.closePanel();
      this.foundPublicRoutes = undefined;
      return;
    }
    if (event.key === "Backspace") {
      this.selectedRegionId = undefined;
    }
    if (!event.key) {
      // console.log("SearchRoute:onSearchRegionTextKeyUp-notEventKey", event);
      return;
    }
    if (this.searchRegionText && this.searchRegionText.length > 2) {
      const result = await this.globalService.searchRefRegionListByName(this.searchRegionText);
      if (result.status !== EnumGlobalStatusCode.Success) {
        // const apiErr = this.createApiError(result, "searchRefRegionListByName");
        // this.showError.emit(apiErr);
        return;
      }
      this.foundRefRegions = result.refRegions;
      const refRegion = this.findRefRegionInFoundList(this.searchRegionText);
      if (refRegion) {
        this.searchRegionText = refRegion.name;
        this.selectedRegionId = refRegion.id;
        this.foundPublicRoutes = undefined;
      } else {
        this.selectedRegionId = undefined;
      }
    }
  }
  public onFoundRegionSelected(event: MatAutocompleteSelectedEvent) {
    // console.log("SearchRoute:onFoundRegionSelected-event", event);
    const selectedRefRegion = this.getSelectedRefRegion(this.searchRegionText);
    // console.log("SearchRoute:onSearchRegionTextKeyUp-selectedrefRegion", selectedRefRegion);
    this.searchRegionText = selectedRefRegion.name;
    this.selectedRegion = selectedRefRegion;
    this.selectedRegionId = selectedRefRegion.id;
    this.foundPublicRoutes = undefined;
  }
  private findRefRegionInFoundList(name: string) {
    if (!this.foundRefRegions) { return; }
    return this.foundRefRegions.find(p => p.name.toUpperCase() === name.toUpperCase());
  }
  private getSelectedRefRegion(name: string) {
    for (const xRefRegion of this.foundRefRegions) {
      if (xRefRegion.name === name) {
        return xRefRegion;
      }
    }
  }

  public onClearDebugLogClick() {
    this.debugLog.splice(0);
  }


  public onRemoveAutoStartClick() {
    this.removeItemInLocalStorage("t4y-maps_auto-start")
  }
  private removeItemInLocalStorage(item: string) {
    const localStorage = window.localStorage;
    localStorage.removeItem(item);
  }



}
