import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

import { CategoryNumberValue } from "src/app/models/category-value";
import { IScatterData, IScatterPoint } from "src/app/models/chart-types";
import { getSteepnessColor, getSteepnessName, getSurfaceColor, getSurfaceName, getWaytypeColor, getWaytypeName } from "./show-route-utils";
import { PRoute } from "src/app/models/p-route";
import { convertORSMoveType, getRouteDurationCorrectionFactor, getRouteTypeName } from "../../maps-utils/utils-route";
import { generateElevationChartData } from "src/app/utils/utils-charts";


@Component({
  selector: "app-show-route-component",
  templateUrl: "./show-route.component.html",
  styleUrls: ["./show-route.component.scss"]
})
export class ShowRouteComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() currentRoute: PRoute;
  @Input() currentGeojsonRoute: any;
  @Input() steepnessExtra: any;
  @Input() routeName: string;
  @Input() languageCode: string;

  @Output() close: EventEmitter<string> = new EventEmitter();
  @Output() pointedIndexChanged: EventEmitter<number> = new EventEmitter();


  @ViewChild("drawboard") drawBoardEl: ElementRef;

  public feature: any;
  public moveType: string;
  public routeDistance: number;
  public routeDuration: number;
  public routeDurationHours: number;
  public routeDurationMinutes: number;
  public routeAscent: number;
  public routeDescent: number;
  public minElevation: number;
  public maxElevation: number;

  public point: number[];
  public elevationChartData: IScatterData;
  public surfaceChartData: number[][];
  public surfaceList: CategoryNumberValue[];
  public waytypeChartData: number[][];
  public waytypeList: CategoryNumberValue[];
  public steepnessChartData: number[][];

  public showSurfaceProfile: boolean;
  public surfaceSummaries: any;
  public showWaytypeProfile: boolean;
  public waytypeSummaries: any;
  public showSteepnessProfile: boolean;
  public steepnessSummaries: any;


  constructor(
    private location: Location,
    private translate: TranslateService,
  ) { }

  public ngOnInit() {
    // console.log("ShowRoute:ngOnInit-currentRoute", this.currentRoute);
    // console.log("ShowRoute:ngOnInit-currentGeojsonRoute", this.currentGeojsonRoute);
    // console.log("ShowRoute:ngOnInit-steepnessExtra", this.steepnessExtra);
  }
  public async ngOnChanges(changes: SimpleChanges) {
    // console.log("ShowRoute:onChanges-changes", changes);
    if (this.currentRoute) {
      this.currentGeojsonRoute = this.currentRoute.geojsonRoute;
      this.routeName = this.currentRoute.name;
    }
    if (!this.currentGeojsonRoute) { return; }

    // route-statistics
    this.feature = this.currentGeojsonRoute.features[0];
    this.moveType = this.currentGeojsonRoute.metadata.query.profile;
    // this.moveType = this.currentRoute.plannerOptions.moveType;
    // console.log("ShowRoute:onChanges-moveType", this.moveType);
    this.routeDistance = Math.round(this.feature.properties.summary.distance) * 0.001;
    this.routeDuration = Math.round(this.feature.properties.summary.duration / 60);
    let routeDuration = this.routeDuration;
    const moveTypeX = convertORSMoveType(this.moveType);
    const corrFactor = getRouteDurationCorrectionFactor(moveTypeX);
    routeDuration = Math.floor(routeDuration * corrFactor);
    this.routeDurationHours = Math.floor(routeDuration / 60);
    this.routeDurationMinutes = routeDuration - this.routeDurationHours * 60;
    this.routeAscent = Math.round(this.feature.properties.ascent);
    this.routeDescent = Math.round(this.feature.properties.descent);
    this.generateMinMaxElevation();
    this.elevationChartData = generateElevationChartData(this.currentGeojsonRoute);
    this.generateSurfaceChartData();
    this.generateWaytypeChartData();
    this.generateSteepnessChartData();

    // route-extras
    const extras = this.feature.properties.extras;

    // surface-list
    if (extras.surface) {
      this.surfaceSummaries = extras.surface.summary;
      this.surfaceList = new Array<CategoryNumberValue>();
      if (extras && extras.surface) {
        const summary = extras.surface.summary;
        for (const catSum of summary) {
          const catValue = {} as CategoryNumberValue;
          catValue.category = catSum.value;
          catValue.value = catSum.distance;
          catValue.name = getSurfaceName(catValue.category, this.languageCode);
          this.surfaceList.push(catValue);
        }
      }
    }
    // console.log("ShowRoute:ngOnInit-surfaceList", this.surfaceList);

    // waytype-list
    if (extras.waytypes) {
      this.waytypeSummaries = extras.waytypes.summary;
      this.waytypeList = new Array<CategoryNumberValue>();
      if (extras && extras.waytypes) {
        const summary = extras.waytypes.summary;
        for (const catSum of summary) {
          const catValue = {} as CategoryNumberValue;
          catValue.category = catSum.value;
          catValue.value = catSum.distance;
          catValue.name = getWaytypeName(catValue.category, this.languageCode);
          this.waytypeList.push(catValue);
        }
      }
    }
    // console.log("ShowRoute:ngOnInit-wayTypeList", this.waytypeList);

    // steepness
    if (extras.steepness) {
      if (this.steepnessExtra) { this.steepnessSummaries = this.steepnessExtra.summary; }
    }

  }

  public ngAfterViewInit() {
    // console.log("ShowRoute:ngAfterViewInit");
    if (!this.currentGeojsonRoute) { return; }
  }
  public ngOnDestroy() {
  }


  private generateElevationChartData_old() {
    // console.log("ShowRoute:generateElevationChartData");

    let wpLast = null;
    let sumDist = 0.0;
    this.elevationChartData = ({} as IScatterData);
    this.elevationChartData.points = new Array<IScatterPoint>();
    for (const wp of this.feature.geometry.coordinates) {
      if (wpLast) {
        const deltaX = wp[0] - wpLast[0];
        const deltaY = wp[1] - wpLast[1];
        const dist = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        sumDist += dist;
        const elevation = wp[2];
        const point = {} as IScatterPoint;
        point.x = sumDist;
        point.y = elevation;
        this.elevationChartData.points.push(point);
      }
      wpLast = wp;
    }
    // console.log("ShowRoute:generateChartData-sumDist", sumDist);
    const fact = this.routeDistance / sumDist;
    // console.log("ShowRoute:generateChartData-fact", fact);
    for (const p of this.elevationChartData.points) {
      p.x = p.x * fact;
      p.x = Math.round(p.x * 1000) / 1000;
    }

    // console.log("ShowRoute:generateChartData-elevationData", this.elevationData);
  }

  private generateSurfaceChartData() {
    // console.log("ShowRoute:generateSurfaceChartData");

    if (!this.feature.properties.extras.surface) { return; }
    this.surfaceChartData = this.feature.properties.extras.surface.values;
    // console.log("ShowRoute:generateSurfaceChartData-surfaceChartData", this.surfaceChartData);
  }

  private generateWaytypeChartData() {
    // console.log("ShowRoute:generateWaytypeChartData");

    if (!this.feature.properties.extras.waytypes) { return; }
    this.waytypeChartData = this.feature.properties.extras.waytypes.values;
    // console.log("ShowRoute:generateSurfaceChartData-waytypeChartData", this.waytypeChartData);
  }

  private generateSteepnessChartData() {
    // console.log("ShowRoute:generateSteepnessChartData");
    if (!this.steepnessExtra) { return; }
    this.steepnessChartData = this.steepnessExtra.values;
    // console.log("ShowRoute:generateSteepnessChartData-steepnessChartData", this.steepnessChartData);
  }

  private generateMinMaxElevation() {
    // console.log("ShowRoute:generateMinMaxElevation-feature", this.feature);

    this.minElevation = Infinity;
    this.maxElevation = -1000;
    for (const wp of this.feature.geometry.coordinates) {
      if (wp[2] < this.minElevation) { this.minElevation = wp[2]; }
      if (wp[2] > this.maxElevation) { this.maxElevation = wp[2]; }
    }
  }

  public onPointedIndexChanged(index: number) {
    // console.log("ShowRoute:onPointedIndexChanged-index", index);
    this.pointedIndexChanged.emit(index);
  }

  public onBack() {
    this.close.emit();
  }
  public onBackToMap() {
    this.close.emit("toMap");
  }

  public getSurfaceName(id: number) {
    return getSurfaceName(id, this.languageCode);
  }
  public getSurfaceColor(id: number) {
    return getSurfaceColor(id);
  }

  public getWaytypeName(id: number) {
    return getWaytypeName(id, this.languageCode);
  }
  public getWaytypeColor(id: number) {
    return getWaytypeColor(id);
  }

  public getSteepnessName(id: number) {
    return getSteepnessName(id, this.languageCode);
  }
  public getSteepnessColor(id: number) {
    return getSteepnessColor(id);
  }

}
