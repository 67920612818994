<!-- route-planner -->
<div *ngIf="true" style="padding:0.5rem;">

    <div style="display:flex; padding:0.5rem;">
        <img style="width:30px; height:30px;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
            title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onRoutePlannerBackClick()">
        <!-- title -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.RPL.DLG.TITLE' | translate}}</span>
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- change-to-map -->
        <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
            alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onRoutePlannerBackClick()">
    </div>
    <hr style="margin-top:0.2rem;margin-bottom:0.5rem;">

    <!-- edit waypoints -->
    <div style="margin:0;">
        <app-edit-waypoints-component [uiType]="uiType" [wayPoints]="routeWayPoints"
            [backToStart]="currentRoute.backToStart" [isRoundRoute]="isRoundRoute" [currentLocation]="currentLocation"
            [savedPlaces]="savedPlaces" [wayPointPositionOnMap]="wayPointPositionOnMap" [sessionId]="sessionId"
            [searchParameter]="searchParameter" [map]="map" [selectedWayPointIndex]="selectedWayPointIndex"
            [languageCode]="languageCode" (selectedWayPointForMapChange)="onSelectedWayPointForMapChange($event)"
            (backToStartChange)="onBackToStartChange($event)" (redrawWayPoints)="onRedrawWayPoints()"
            (recalculateRoute)="onRecalculateRoute()" (reconvertRouteToUrl)="onUpdateUrl()">
        </app-edit-waypoints-component>
    </div>

    <!-- movetype -->
    <div style="margin-top:1.0rem; margin-left:1.0rem;">
        <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
            [style.background-color]="currentRoute.plannerOptions.moveType==='bike' ? 'greenyellow' : ''"
            src="./assets/icons/bike.svg" title="{{'MAPS.RPL.DLG.BYBIKE_T' | translate}}" alt="symbol"
            (click)="onSetRoutePlannerMoveType('bike')">
        <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
            [style.background-color]="currentRoute.plannerOptions.moveType==='ebike' ? 'greenyellow' : ''"
            src="./assets/icons/bicycle-electric.svg" title="{{'MAPS.RPL.DLG.BYEBIKE_T' | translate}}" alt="symbol"
            (click)="onSetRoutePlannerMoveType('ebike')">
        <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
            [style.background-color]="currentRoute.plannerOptions.moveType==='bike-road' ? 'greenyellow' : ''"
            src="./assets/icons/bike-road.svg" title="{{'MAPS.RPL.DLG.BYBIKEROAD_T' | translate}}" alt="symbol"
            (click)="onSetRoutePlannerMoveType('bike-road')">
        <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
            [style.background-color]="currentRoute.plannerOptions.moveType==='mtb' ? 'greenyellow' : ''"
            src="./assets/icons/bike-mtb.svg" title="{{'MAPS.RPL.DLG.BYMTB_T' | translate}}" alt="symbol"
            (click)="onSetRoutePlannerMoveType('mtb')">
        <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
            [style.background-color]="currentRoute.plannerOptions.moveType==='walking' ? 'greenyellow' : ''"
            src="./assets/icons/walking.svg" title="{{'MAPS.RPL.DLG.WALKING_T' | translate}}" alt="symbol"
            (click)="onSetRoutePlannerMoveType('walking')">
        <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
            [style.background-color]="currentRoute.plannerOptions.moveType==='hiking' ? 'greenyellow' : ''"
            src="./assets/icons/hiking.svg" width="24px" title="{{'MAPS.RPL.DLG.HIKING_T' | translate}}" alt="symbol"
            (click)="onSetRoutePlannerMoveType('hiking')">
        <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
            [style.background-color]="currentRoute.plannerOptions.moveType==='car' ? 'greenyellow' : ''"
            src="./assets/icons/car-hatchback.svg" title="{{'MAPS.RPL.DLG.BYCAR_T' | translate}}" alt="symbol"
            (click)="onSetRoutePlannerMoveType('car')">
    </div>

    <!-- round-route-options -->
    <div *ngIf="isRoundRoute"
        style="margin:0.5rem; background-color:antiquewhite; border:2px solid grey; border-radius: 20px; padding:0.5rem;">
        <span>{{'MAPS.RPL.DLG.ROUND-ROUTE-OPTIONS_T' | translate}}:</span><br>
        <!-- round-route-length -->
        <span>{{'MAPS.RPL.DLG.ROUND-ROUTE-LENGTH_T' | translate}}:</span>
        <input type="number"
            style="margin-top:0.5rem; margin-left:0.5rem; max-width:60px; height:15px; border-radius:5px; font-size:medium;"
            min="5" max="100" step="5" [(ngModel)]="roundRouteLength"
            (change)="onRoundRootOptionsChange($event)" /><span> km</span><br>
        <span>{{'MAPS.RPL.DLG.ROUTE-POINTS_T' | translate}}:</span>
        <input type="number"
            style="margin-top:0.1rem; margin-left:0.5rem; max-width:60px; height:15px; border-radius:5px; font-size:medium;"
            min="2" max="25" step="1" [(ngModel)]="roundRoutePoints" (change)="onRoundRootOptionsChange($event)" />
        <span style="margin-left:0.5rem;">{{'MAPS.RPL.DLG.ROUND-ROUTE-LENGTH_T' | translate}}</span><br>
        <span>{{'MAPS.RPL.DLG.ROUTE-SEED_T' | translate}}:</span>
        <input type="number"
            style="margin-top:0.1rem; margin-left:1.0rem; max-width:60px; height:15px; border-radius:5px; font-size:medium;"
            min="0" max="100" step="1" [(ngModel)]="roundRouteSeed" (change)="onRoundRootOptionsChange($event)" />
        <span style="margin-left:0.5rem;">{{'MAPS.RPL.DLG.FOR-RANDOMISATION_T' | translate}}</span><br>
        <button mat-raised-button color="primary" style="margin-top:0.5rem;" (click)="onRecalculateRouteClick()"
            title="clear route">{{'MAPS.RPL.DLG.RECALC-ROUTE' | translate}}</button>
    </div>

    <!-- bike-options -->
    <div *ngIf="isMoveTypeBike" style="margin:0.5rem;">
        <!-- avoid steps -->
        <mat-slide-toggle color="primary" style="margin-left:0.5rem;"
            [(ngModel)]="currentRoute.plannerOptions.avoidSteps"
            (change)="onAvoidStepsChange()">{{'MAPS.RPL.DLG.AVOID-STEPS' | translate}}
        </mat-slide-toggle>
    </div>
    <!-- car-options -->
    <div *ngIf="currentRoute.plannerOptions.moveType==='car'" style="margin:0.5rem;">
        <!-- avoid tollways -->
        <mat-slide-toggle color="primary" style="margin-left:0.5rem;"
            [(ngModel)]="currentRoute.plannerOptions.avoidTollways"
            (change)="onAvoidTollwaysChange()">{{'MAPS.RPL.DLG.AVOID-TOLLWAYS' | translate}}
        </mat-slide-toggle>
        <mat-slide-toggle color="primary" style="margin-left:0.5rem;"
            [(ngModel)]="currentRoute.plannerOptions.avoidHighways"
            (change)="onAvoidHighwaysChange()">{{'MAPS.RPL.DLG.AVOID-HIGHWAYS' | translate}}
        </mat-slide-toggle>
    </div>
    <hr style="margin-top:0.3rem; margin-left:0.5rem; margin-right:0.5rem; margin-bottom:0.5rem;">

    <!-- route-data -->
    <!-- ORS-Error -->
    <div *ngIf="orsPostErrorText" style="margin:0.5rem; color:crimson; max-width:360px">
        <hr style=" margin-top:0.3rem; margin-bottom:0.5rem;">
        <span style="margin-top:0.5rem; font-size:x-large;">{{'MAPS.RPL.DLG.ROUTE-CALC-ERROR' | translate}}</span><br>
        <span style="margin-top:0.5rem; font-size:medium;">{{orsPostErrorText}}</span>
    </div>

    <!-- route-name -->
    <div style="margin:0;">
        <mat-form-field class="mat-field" style="margin:0;padding-bottom:0;">
            <input #nameInput matInput class="mat-input" placeholder="{{'MAPS.RPL.DLG.R-NAME' | translate}}"
                spellcheck="false" [(ngModel)]="currentRoute.name" (keyup)="onRouteNameKeyUp($event)"
                (blur)="onRouteNameBlur()">
        </mat-form-field>
    </div>



    <!-- route result -->
    <div *ngIf="geojsonRoute" style="margin:0.5rem; font-size:large;">
        <span>{{'MAPS.RPL.DLG.RES_DIST' | translate}} {{routeDistance/1000 | number:'1.0-1'}} km</span><br>
        <span>{{'MAPS.RPL.DLG.RES_DUR' | translate}} </span>
        <span *ngIf="routeDurationHour === 0">{{routeDurationMinute}} min</span>
        <span *ngIf="routeDurationHour > 0">{{routeDurationHour}}h {{routeDurationMinute}} min</span><br>
        <!-- <span *ngIf="estimatedArrivalTime">{{'MAPS.RPL.DLG.RES_ARRIVAL-TIME' | translate}}
          {{estimatedArrivalTime | date:'H:mm'}}</span><br> -->
        <hr style="margin-top:0.3rem; margin-bottom:0.5rem;">

        <!-- tests nur wenn rock eingeloggt ist-->
        <!-- <ng-container *ngIf="loggedInUser && loggedInUser.id===1"> -->
        <!-- <ng-container *ngIf="false">
          <hr>
          <span>Test-Version: 2022/06/13.1</span><br>
          <span translate> MAPS.TEST</span><br>
          <span>UserId: {{loggedInUser.id}}</span><br>
          <span>Calculated route polyline length: {{calculatedRoutePolylineLength}}</span><br>
          <span>Current distance: {{currentDistance}} m<br></span>
          <span>Distance from Start: {{calculatedDoneDistance}} m<br></span>
          <span>Duration from Start: {{calculatedDurationFromStart}} sec<br></span>
          <span>Velocity since start: {{totalVelocity}} m/s<br></span>
          <span>Remaining distance: {{remainingDistance}} m<br></span>
          <span>Remaining duration: {{remainingDuration}} sec<br></span>
          <span *ngIf="currentRouteStepResult">Current step index: {{currentRouteStepResult.index}}<br></span>
          <span *ngIf="currentRouteStepResult">Current step type: {{currentRouteStepResult.step.type}}<br></span>
          <span>Current step instruction: {{currentRouteStepInstructionA}}</span><br>
          <span>Current step instruction: {{currentRouteStepInstructionB}}</span><br>
          <span>Current step instruction: {{currentRouteStepInstructionC}}</span>
          <br>
        </ng-container> -->
    </div>

    <!-- draw planned route -->
    <div *ngIf="geojsonRoute"
        style="margin:0.5rem; background-color: antiquewhite; border: 2px solid grey; border-radius: 20px; padding:0.5rem;">
        <!-- <mat-slide-toggle color="primary" style="margin-left:2.0rem;" [(ngModel)]="hideRoute"
            (change)="onDrawRouteChange()">{{'MAPS.RPL.DLG.HIDE-ROUTE' | translate}}
        </mat-slide-toggle> -->
        <span style="font-size:large">{{'MAPS.RPL.DLG.DRAW-STYLE_TITLE' | translate}}</span><br>
        <mat-radio-group class="routestyle-radio-group" [(ngModel)]="drawRouteStyle">
            <mat-radio-button class="routestyle-radio-button" [value]="'solid'"
                (click)="onDrawRouteSurfaceChange('solid')">{{'MAPS.RPL.DLG.SOLID-ROUTE' |
                translate}}</mat-radio-button><br>
            <mat-radio-button class="routestyle-radio-button" [value]="'transparent'"
                (click)="onDrawRouteSurfaceChange('transparent')">{{'MAPS.RPL.DLG.TRANSPARENT-ROUTE' |
                translate}}</mat-radio-button><br>
            <mat-radio-button *ngIf="surfaceSummary" class="routestyle-radio-button" [value]="'surface-type'"
                (click)="onDrawRouteSurfaceChange('surface-type')">{{'MAPS.RPL.DLG.SHOW-SURFACE-TYPES' |
                translate}}<br></mat-radio-button><br>
            <mat-radio-button *ngIf="waytypeSummary" class="routestyle-radio-button" [value]="'way-type'"
                (click)="onDrawRouteSurfaceChange('way-type')">{{'MAPS.RPL.DLG.SHOW-WAY-TYPES' |
                translate}}<br></mat-radio-button><br>
            <mat-radio-button class="routestyle-radio-button" [value]="'steepness'"
                (click)="onDrawRouteSurfaceChange('steepness')">{{'MAPS.RPL.DLG.SHOW-STEEPNESS' |
                translate}}</mat-radio-button><br>
            <mat-radio-button class="routestyle-radio-button" [value]="'style-g'"
                (click)="onDrawRouteSurfaceChange('style-g')">{{'MAPS.RPL.DLG.SHOW-STYLEG' |
                translate}}</mat-radio-button><br>
        </mat-radio-group>
    </div>

    <!-- surface-type -->
    <div *ngIf="geojsonRoute && drawRouteStyle==='surface-type'"
        style="margin:0.5rem; background-color: antiquewhite; border: 2px solid grey; border-radius: 20px; padding:0.5rem;">
        <!-- <mat-slide-toggle color="primary" style="margin-left:2.0rem;" [(ngModel)]="drawRouteSurface"
            (change)="onDrawRouteSurfaceChange()">{{'MAPS.RPL.DLG.SHOW-SURFACE-TYPES' | translate}}
        </mat-slide-toggle> -->

        <div *ngIf="drawRouteStyle==='surface-type'" style="margin:0.5rem; font-size:medium;">
            <span>{{'MAPS.RPL.DLG.LEGEND-SURFACE-TYPES' | translate}}</span>
            <div style="margin-top:0.5rem; display:flex">
                <div style="margin-top:-5px;">
                    <div *ngFor="let summary of surfaceSummary">
                        <hr size="4px" width="50px" noshade style="margin:15px; margin-left:0;"
                            [color]="getSurfaceColor(summary.value)">
                    </div>
                </div>
                <div style="font-size:smaller;">
                    <div *ngFor="let summary of surfaceSummary" style="line-height:19px;">
                        <span style="margin-left:0.5rem;">{{getSurfaceName(summary.value)}}</span>
                    </div>
                </div>
                <div style="font-size:smaller; margin-left:1.0rem;">
                    <div *ngFor="let summary of surfaceSummary" style="text-align:right; line-height:19px;">
                        <span>{{summary.distance | number: '1.1-1'}}m</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- way-type -->
    <div *ngIf="geojsonRoute && drawRouteStyle==='way-type'"
        style="margin:0.5rem; background-color: antiquewhite; border: 2px solid grey; border-radius: 20px; padding:0.5rem;">

        <div *ngIf="drawRouteStyle==='way-type'" style="margin:0.5rem; font-size:medium;">
            <span>{{'MAPS.RPL.DLG.LEGEND-WAY-TYPES' | translate}}</span>
            <div style="margin-top:0.5rem; display:flex">
                <div style="margin-top:-5px;">
                    <div *ngFor="let summary of waytypeSummary">
                        <hr size="4px" width="50px" noshade style="margin:15px; margin-left:0;"
                            [color]="getWaytypeColor(summary.value)">
                    </div>
                </div>
                <div style="font-size:smaller;">
                    <div *ngFor="let summary of waytypeSummary" style="line-height:19px;">
                        <span>{{getWaytypeName(summary.value)}}</span>
                    </div>
                </div>
                <div style="font-size:smaller; margin-left:1.0rem;">
                    <div *ngFor="let summary of waytypeSummary" style="text-align:right; line-height:19px;">
                        <span>{{summary.distance | number: '1.1-1'}}m</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- steepness -->
    <div *ngIf="geojsonRoute && drawRouteStyle==='steepness'"
        style="margin:0.5rem; background-color: antiquewhite; border: 2px solid grey; border-radius: 20px; padding:0.5rem;">

        <div *ngIf="drawRouteStyle==='steepness'" style="margin:0.5rem; font-size:medium;">
            <span>{{'MAPS.RPL.DLG.LEGEND-STEEPNESS' | translate}}</span>
            <div style="margin-top:0.5rem; display:flex">
                <div style="margin-top:-5px;">
                    <div *ngFor="let summary of steepnessSummary">
                        <hr *ngIf="summary.distance > 0" size="4px" width="50px" noshade
                            style="margin:15px; margin-left:0;" [color]="getSteepnessColor(summary.value)">
                    </div>
                </div>
                <div style="font-size:smaller;">
                    <div *ngFor="let summary of steepnessSummary" style="line-height:19px;">
                        <ng-container *ngIf="summary.distance > 0">
                            <span *ngIf="summary.value < 0">&#8600;
                                {{getSteepnessName(summary.value)}}</span>
                            <span *ngIf="summary.value === 0">&ndash;
                                {{getSteepnessName(summary.value)}}</span>
                            <span *ngIf="summary.value > 0">&#8599;
                                {{getSteepnessName(summary.value)}}</span>
                        </ng-container>
                    </div>
                </div>
                <div style="font-size:smaller; margin-left:1.0rem;">
                    <div *ngFor="let summary of steepnessSummary" style="text-align:right; line-height:19px;">
                        <span *ngIf="summary.distance > 0">{{summary.distance | number: '1.0-0'}}m</span>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- route-methods -->
    <div *ngIf="true" style="margin-top:0.5rem; margin-left:0;">
        <button *ngIf="countWayPoints>0" mat-raised-button color="primary" class="route-button-short"
            (click)="onClearRouteClick()">
            <img width="20px" style="margin-bottom:5px; margin-right:0.5rem;" src="./assets/icons/close.svg"
                title="clear route">{{'MAPS.RPL.DLG.CLEAR-ROUTE'
            | translate}}</button>
        <button *ngIf="!isRoundRoute && geojsonRoute" mat-raised-button color="primary" class="route-button-short"
            (click)="onReverseRouteClick()">
            <img width="20px" style="margin-right:0.5rem;"
                src="./assets/icons/compare-vertical.svg">{{'MAPS.RPL.DLG.REVERSE-ROUTE' |
            translate}}
        </button><br>
        <button *ngIf="geojsonRoute" mat-raised-button color="primary" class="route-button-long"
            (click)="onStartNavigationClick()">
            <img height="20px" style="margin-right:0.5rem;"
                src="./assets/icons/navigation.svg">{{'MAPS.RPL.DLG.START-NAVI' | translate}}
        </button><br>
        <button *ngIf="geojsonRoute && currentLocation" mat-raised-button color="primary" class="route-button-long"
            (click)="onPlanRouteToStartPointTrip4YouMapsClick()">
            <img height="20px" style="margin-right:0.5rem;"
                src="./assets/icons/navigation.svg">{{'MAPS.RPL.DLG.PLAN-ROUTE-TO-START-TRIP4YOUMAPS' | translate}}
        </button><br>
        <button *ngIf="geojsonRoute && currentLocation" mat-raised-button color="primary" class="route-button-long"
            (click)="onPlanRouteToStartPointGoogleClick()">
            <img height="20px" style="margin-right:0.5rem;"
                src="./assets/icons/navigation.svg">{{'MAPS.RPL.DLG.PLAN-ROUTE-TO-START-GOOGLE' | translate}}
        </button><br>
        <button *ngIf="geojsonRoute" mat-raised-button color="primary" class="route-button-short"
            (click)="onShowRouteDetailsClick()">
            <img width="20px" style="margin-right:0.5rem;" src="./assets/icons/eye.svg">{{'MAPS.RPL.DLG.SHOW-DETAILS' |
            translate}}
        </button>
        <button *ngIf="geojsonRoute" mat-raised-button color="primary" class="route-button-short"
            (click)="onZoomToRouteClick()">
            <img width="20px" style="margin-right:0.5rem;"
                src="./assets/icons/overscan.svg">{{'MAPS.RPL.DLG.ZOOM-TO-ROUTE' | translate}}
        </button>
    </div>

    <!-- methods with name needed -->
    <div style="margin-top:0.rem; margin-left:0;">
        <!-- share -->
        <button *ngIf="geojsonRoute" mat-raised-button color="primary" class="route-button-short"
            (click)="onShareRouteClick()">
            <img width="20px" style="margin-right:0.5rem;"
                src="./assets/icons/share-outline.svg">{{'MAPS.RPL.DLG.SHARE-ROUTE' |
            translate}}
        </button>
        <!-- save-as-gpx -->
        <a [href]="fileUrlRoute" download="{{currentRoute.name ? currentRoute.name + '.gpx' : 'my-route.gpx'}}">
            <button *ngIf="geojsonRoute" mat-raised-button color="primary" class="route-button-short"
                (click)="onSaveRouteAsGpxClick()">
                <img width="20px" style="margin-right:0.5rem;" src="./assets/icons/download-outline.svg">
                {{'MAPS.RPL.DLG.GPX-FILE' | translate}}
            </button>
        </a>
        <!-- save-localy -->
        <button *ngIf="geojsonRoute" mat-raised-button color="primary" class="route-button-long"
            (click)="onSaveRouteLocalyClick()">
            <img #saveRouteIcon1 width="20px" style="margin-right:0.5rem;"
                src="./assets/icons/bookmark-outline.svg">{{'MAPS.RPL.DLG.SAVE-ROUTE-LOCALY' | translate}}
        </button>
        <!-- save-cloud -->
        <ng-container *ngIf="loggedInUser">
            <button *ngIf="geojsonRoute" mat-raised-button color="primary" class="route-button-long"
                (click)="onSaveRouteCloudClick()">
                <img #saveRouteIcon2 width="20px" style="margin-right:0.5rem;"
                    src="./assets/icons/bookmark-outline.svg">{{'MAPS.RPL.DLG.SAVE-ROUTE-CLOUD' | translate}}
            </button>
        </ng-container>
        <ng-container *ngIf="!loggedInUser">
            <button *ngIf="geojsonRoute" mat-raised-button disabled=true color="disabled" class="route-button-long"
                (click)="onSaveRouteCloudClick()">
                <img #saveRouteIcon2 width="20px" style="margin-right:0.5rem;"
                    src="./assets/icons/bookmark-outline.svg">{{'MAPS.RPL.DLG.SAVE-ROUTE-CLOUD' | translate}}
            </button>
            <div style="padding:0.5rem; color:red">
                <span *ngIf="languageCode !== 'de'">To store the route on server you have to be logged in.</span>
                <span *ngIf="languageCode === 'de'">Zum Speichern der Route am Server musst du eingelogged sein.</span>
            </div>
        </ng-container>
        <ng-container *ngIf="isTestUser && !isCurrentRouteIdPublished">
            <button *ngIf="geojsonRoute" mat-raised-button class="route-button-long" (click)="onPublishRouteClick()">
                <img #saveRouteIcon2 width="20px" style="margin-right:0.5rem;"
                    src="./assets/icons/publish.svg">{{'MAPS.RPL.DLG.PUBLISH-ROUTE' | translate}}
            </button>
        </ng-container>
        <ng-container *ngIf="isUserRouteAdmin && isCurrentRoutePublic">
            <button *ngIf="geojsonRoute" mat-raised-button class="route-button-long"
                (click)="onPublicRouteUpdateClick()">
                <img #saveRouteIcon2 width="20px" style="margin-right:0.5rem;"
                    src="./assets/icons/publish.svg">{{'MAPS.RPL.DLG.PUBLIC-ROUTE-UPDATE' | translate}}
            </button>
        </ng-container>
    </div>

    <!-- help text if route has no waypoints -->
    <div *ngIf="countWayPoints<1" style="margin-left:1.0rem;">
        <ng-container *ngIf="languageCode=='de'">
            <span style="font-size:large;">Wie plane ich eine Route?</span><br>
            <span>Definiere Start- und Endpunkt deiner Route mittels Punkt-Menü oder klicke auf die Karte.</span><br>
            <button *ngIf="uiType=='S'" (click)="onRoutePlannerBackClick()"
                style="margin-top:0.5rem; margin:auto;">Zurück zur Karte
                <img style="cursor:pointer; margin-left:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
                    alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}">
            </button>
        </ng-container>
        <ng-container *ngIf="languageCode!='de'">
            <span style="font-size:large;">How to plan a route?</span><br>
            <span>Define start and end point of your route with the point menu or click on the map.</span><br>
            <button *ngIf="uiType=='S'" (click)="onRoutePlannerBackClick()" style="margin-top:0.5rem; margin:auto;">Back
                to the map
                <img style="cursor:pointer; margin-left:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
                    alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}">
            </button>
        </ng-container>
    </div>


</div>