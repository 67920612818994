
// regions
export function getAvailableCountries(languageCode: string) {
    let regionIds = [];
    if (languageCode === "de") { regionIds = [53, 57, 54, 68, 77, 70, 90, 87, 88, 74, 78]; }
    if (languageCode === "en") { regionIds = [68, 53, 70, 77, 90, 88, 87, 57, 74, 78]; }
    // regionIds = [70, 88, 57, 90, 68, 87, 77, 54, 53, 1348, 1347, 1346, 1345, 1344, 1343, 1342, 1341, 1340, 1961, 1960, 2000];
    return regionIds;
}
export function getAvailableRegions(parentRegionId: number) {
    let regionIds = [];
    if (parentRegionId === 53) { regionIds = [1348, 1347, 1346, 1345, 1344, 1342, 1341, 1340]; } // Austria
    if (parentRegionId === 68) { regionIds = [1399, 1387, 1400, 1391, 1398, 1391, 1392, 1402, 1989]; } // Italy
    if (parentRegionId === 74) { regionIds = [1960, 1961]; } // Portugal
    if (parentRegionId === 78) { regionIds = [2000]; } // Spaint
    return regionIds;
}

export function getRegionName(regionId: number, languageCode: string) {
    // console.log("utils-regions:getRegionName-regionId", regionId);
    let name = regionId.toString();
    if (languageCode === "de") {
        if (regionId === 53) { name = "Österreich"; }
        if (regionId === 57) { name = "Deutschland"; }
        if (regionId === 54) { name = "Schweiz"; }
        if (regionId === 68) { name = "Italien"; }
        if (regionId === 77) { name = "Slowenien"; }
        if (regionId === 70) { name = "Kroatien"; }
        if (regionId === 90) { name = "Ungarn"; }
        if (regionId === 87) { name = "Slowakei"; }
        if (regionId === 88) { name = "Tschechien"; }
        if (regionId === 74) { name = "Portugal"; }
        if (regionId === 78) { name = "Spanien"; }

        if (regionId === 1348) { name = "Burgenland"; }
        if (regionId === 1347) { name = "Kärnten"; }
        if (regionId === 1346) { name = "Niederösterreich"; }
        if (regionId === 1345) { name = "Oberösterreich"; }
        if (regionId === 1344) { name = "Salzburg"; }
        if (regionId === 1343) { name = "Steiermark"; }
        if (regionId === 1342) { name = "Tirol"; }
        if (regionId === 1341) { name = "Vorarlberg"; }
        if (regionId === 1340) { name = "Wien"; }

        if (regionId === 1399) { name = "Friaul-Julisch Venetien"; }
        if (regionId === 1387) { name = "Venetien"; }
        if (regionId === 1400) { name = "Emilia-Romagna"; }
        if (regionId === 1396) { name = "Lombardei"; }
        if (regionId === 1391) { name = "Toskana"; }
        if (regionId === 1398) { name = "Latium"; }
        if (regionId === 1392) { name = "Apulien"; }
        if (regionId === 1402) { name = "Basilikata"; }
        if (regionId === 1989) { name = "Elba"; }

        if (regionId === 1960) { name = "Azoren"; }
        if (regionId === 1961) { name = "Madeira"; }

        if (regionId === 2000) { name = "La Gomera"; }
    }
    if (languageCode === "en") {
        if (regionId === 68) { name = "Italy"; }
        if (regionId === 53) { name = "Austria"; }
        if (regionId === 70) { name = "Croatia"; }
        if (regionId === 77) { name = "Slovenia"; }
        if (regionId === 90) { name = "Hungary"; }
        if (regionId === 88) { name = "Chechia"; }
        if (regionId === 87) { name = "Slovakia"; }
        if (regionId === 57) { name = "Germany"; }
        if (regionId === 74) { name = "Portugal"; }
        if (regionId === 78) { name = "Spain"; }

        if (regionId === 1348) { name = "Burgenland"; }
        if (regionId === 1347) { name = "Carinthia"; }
        if (regionId === 1346) { name = "Lower Austria"; }
        if (regionId === 1345) { name = "Upper Austria"; }
        if (regionId === 1344) { name = "Salzburg"; }
        if (regionId === 1343) { name = "Styria"; }
        if (regionId === 1342) { name = "Tyrol"; }
        if (regionId === 1341) { name = "Vorarlberg"; }
        if (regionId === 1340) { name = "Vienna"; }

        if (regionId === 1399) { name = "Friuli Venezia Giulia"; }
        if (regionId === 1387) { name = "Veneto"; }
        if (regionId === 1400) { name = "Emilia-Romagna"; }
        if (regionId === 1396) { name = "Lombardy"; }
        if (regionId === 1391) { name = "Tuskany"; }
        if (regionId === 1398) { name = "Latio"; }
        if (regionId === 1392) { name = "Apulia"; }
        if (regionId === 1402) { name = "Basilicata"; }
        if (regionId === 1989) { name = "Elba"; }

        if (regionId === 1960) { name = "Azores"; }
        if (regionId === 1961) { name = "Madeira"; }

        if (regionId === 2000) { name = "La Gomera"; }
    }
    return name;
}
export function getAvailableCountriesDevelop() {
    const regionIds = [68, 53];
    return regionIds;
}
export function getAvailableRegionsDevelop(parentRegionId: number) {
    let regionIds = [];
    if (parentRegionId === 53) { regionIds = [1424, 1428, 1429, 1427, 1426, 1425, 1423, 1422, 1421]; } // Austria
    if (parentRegionId === 68) { regionIds = []; } // Italy
    return regionIds;
}
export function getRegionNameDevelop(regionId: number, languageCode: string) {
    // console.log("utils-regions:getRegionName-regionId", regionId);
    let name = regionId.toString();
    if (languageCode === "de") {
        if (regionId === 53) { name = "Österreich"; }
        if (regionId === 68) { name = "Italien"; }


        if (regionId === 1429) { name = "Burgenland"; }
        if (regionId === 1428) { name = "Kärnten"; }
        if (regionId === 1427) { name = "Niederösterreich"; }
        if (regionId === 1426) { name = "Oberösterreich"; }
        if (regionId === 1425) { name = "Salzburg"; }
        if (regionId === 1424) { name = "Steiermark"; }
        if (regionId === 1423) { name = "Tirol"; }
        if (regionId === 1422) { name = "Vorarlberg"; }
        if (regionId === 1421) { name = "Wien"; }
    }
    if (languageCode === "en") {
        if (regionId === 53) { name = "Austria"; }
        if (regionId === 68) { name = "Italy"; }

        if (regionId === 1429) { name = "Burgenland"; }
        if (regionId === 1428) { name = "Carinthia"; }
        if (regionId === 1427) { name = "Lower Austria"; }
        if (regionId === 1426) { name = "Upper Austria"; }
        if (regionId === 1425) { name = "Salzburg"; }
        if (regionId === 1424) { name = "Styria"; }
        if (regionId === 1423) { name = "Tyrol"; }
        if (regionId === 1422) { name = "Vorarlberg"; }
        if (regionId === 1421) { name = "Vienna"; }
    }
    return name;
}
