import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { GeneralSettings, RouteFormatOptions, RoutePlannerOptions as RoutePlannerOptions } from "src/app/models/map-settings";
import { MapPlaceL } from "src/app/models/mapplace";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { localStoreGeneralSettings, localStoreRouteFormatSettings, localStoreRoutePlannerSettings } from "../maps-utils/utils-maps-local-storage";

@Component({
  selector: "app-settings-component",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() generalSettings: GeneralSettings;
  @Input() routePlannerSettings: RoutePlannerOptions;
  @Input() routeFormatSettings: RouteFormatOptions;

  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() generalSettingsChange: EventEmitter<GeneralSettings> = new EventEmitter();
  @Output() routePlannerSettingsChange: EventEmitter<RoutePlannerOptions> = new EventEmitter();
  @Output() routeFormatSettingsChange: EventEmitter<RouteFormatOptions> = new EventEmitter();

  public generalSettingsChanged: boolean;
  public routePlannerSettingsChanged: boolean;
  public routeFormatSettingsChanged: boolean;
  public routeFormatSteepnessORS: boolean;
  public routeFormatSteepnessT4Y: boolean;


  constructor() { }

  public async ngOnInit() {
    console.log("Settings:ngOnInit-routeFormatSettings", this.routeFormatSettings);
    this.routeFormatSteepnessORS = this.routeFormatSettings.drawSteepness === "ORS"
    this.routeFormatSteepnessT4Y = this.routeFormatSettings.drawSteepness === "T4Y"

    if (!this.generalSettings.trackingTimeIntervall) { this.generalSettings.trackingTimeIntervall = 30 };
  }
  public ngAfterViewInit() {
    // console.log("Settings:ngAfterViewInit");
  }
  public ngOnDestroy(): void {
  }

  public onSettingsBackClick() {
    this.back.emit();
  }

  // general settings
  public onGeneralSettingsChanged() {
    this.generalSettingsChanged = true;
    this.generalSettingsChange.emit(this.generalSettings);
  }
  public onSaveGeneralSettingsClick() {
    this.generalSettingsChanged = false;
    localStoreGeneralSettings(this.generalSettings);
  }

  // route-planner settings
  public onRoutePlannerMoveTypeChange(moveType: string) {
    this.routePlannerSettingsChanged = true;
    this.routePlannerSettings.moveType = moveType;
    this.routePlannerSettingsChange.emit(this.routePlannerSettings);
  }
  public onSaveRoutePlannerSettingsClick() {
    this.routePlannerSettingsChanged = false;
    localStoreRoutePlannerSettings(this.routePlannerSettings);
  }

  public onRouteFormatSettingsORSChange() {
    this.routeFormatSettingsChanged = true;
    if (this.routeFormatSteepnessORS) {
      this.routeFormatSteepnessT4Y = false;
      this.routeFormatSettings.drawSteepness = "ORS";
    } else {
      this.routeFormatSteepnessT4Y = true;
      this.routeFormatSettings.drawSteepness = "T4Y";
    }
    this.routeFormatSettingsChange.emit(this.routeFormatSettings);
  }
  public onRouteFormatSettingsT4YChange() {
    this.routeFormatSettingsChanged = true;
    if (this.routeFormatSteepnessT4Y) {
      this.routeFormatSteepnessORS = false;
      this.routeFormatSettings.drawSteepness = "T4Y";
    } else {
      this.routeFormatSteepnessORS = true;
      this.routeFormatSettings.drawSteepness = "ORS";
    }
    this.routeFormatSettingsChange.emit(this.routeFormatSettings);
  }
  public onSaveRouteFormatSettingsClick() {
    this.routeFormatSettingsChanged = false;
    localStoreRouteFormatSettings(this.routeFormatSettings);
  }

  public onTourTimerIntervallClick(intervall: number) {
    this.generalSettings.trackingTimeIntervall = intervall;
    this.generalSettingsChanged = true;
  }
}
