<!-- show track -->

<!-- title -->
<div style="display:flex; padding:0.5rem;">
  <img style="width:30px; height:30px;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
    title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onBack()">
  <!-- title -->
  <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
  <div *ngIf="currentTrack">
    <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.TRACK-DET.TITLE' | translate}}
      {{currentTrack.name}}</span>
  </div>
  <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
  <!-- change-to-map -->
  <img style="cursor:pointer; margin-right:1.0rem;" width=36px src="./assets/icons/home-map-marker.svg" alt="symbol"
    title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onBackToMap()">
</div>
<hr style="margin-top:0.2rem;margin-bottom:0.5rem;">

<!-- show statistics -->
<div *ngIf="currentTrack">

  <!-- debug -->
  <div *ngIf="this.currentTrack.message" style="margin:0.5rem; margin-top:1.0rem; font-size:medium;">
    <span>Debug-message:</span><br>
    <textarea rows="8" cols="40" style="white-space:pre;">{{this.currentTrack.message}}</textarea>
  </div>

  <!-- track-statistics -->
  <div style="margin:0.5rem; margin-top:1.0rem; font-size:x-large;">
    <span *ngIf="trackType==='gpx-route'">{{'MAPS.TRACK-DET.GPX-ROUTE_TITLE' | translate}}</span>
    <span *ngIf="trackType==='track'">{{'MAPS.TRACK-DET.TRACK_TITLE' | translate}}</span>
  </div>
  <div style="margin:0.5rem; margin-right:1.0rem; background-color: floralwhite; font-size:large; padding:0.5rem;">
    <span>{{'MAPS.TRACK-DET.DISTANCE_LBL' | translate}}: {{routeDistanceKm | number: '1.0-3'}} km</span><br>
    <ng-container *ngIf="hasElevationData">
      <span>{{'MAPS.TRACK-DET.ACENT_LBL' | translate}}: {{routeAscent | number: '1.0-1'}} m</span><br>
      <span>{{'MAPS.TRACK-DET.DECENT_LBL' | translate}}: {{routeDescent | number: '1.0-1'}} m</span><br>
      <span>{{'MAPS.TRACK-DET.MIN-ELE_LBL' | translate}}: {{minElevation | number: '1.0-1'}} m</span><br>
      <span>{{'MAPS.TRACK-DET.MAX-ELE_LBL' | translate}}: {{maxElevation | number: '1.0-1'}} m</span><br>
    </ng-container>
    <ng-container *ngIf="timeNeeded">
      <span>{{'MAPS.TRACK-DET.DURATION-NEEDED_LBL' | translate}}:
        {{timeNeeded | number: '1.0-0'}} min</span><br>
      <span>{{'MAPS.TRACK-DET.AVERAGE-SPEED_LBL' | translate}}:
        {{routeDistanceKm / (timeNeeded / 60) | number: '1.0-1'}} km/h</span><br>
    </ng-container>
    <span>{{'MAPS.TRACK-DET.EST-DURATION-BIKE_LBL' | translate}}:</span>
    <span *ngIf="routeDurationNormalBikeHours === 0"> {{routeDurationNormalBikeMin}} min<br></span>
    <span *ngIf="routeDurationNormalBikeHours > 0"> {{routeDurationNormalBikeHours}} h {{routeDurationNormalBikeMin}}
      min<br></span>
    <span>{{'MAPS.TRACK-DET.EST-DURATION-EBIKE_LBL' | translate}}:</span>
    <span *ngIf="routeDurationEBikeHours === 0"> {{routeDurationEBikeMin}} min<br></span>
    <span *ngIf="routeDurationEBikeHours > 0"> {{routeDurationEBikeHours}} h {{routeDurationEBikeMin}} min<br></span>
    <span>{{'MAPS.TRACK-DET.EST-DURATION-FOOT_LBL' | translate}}:</span>
    <span *ngIf="routeDurationHikeHours === 0"> {{routeDurationHikeMin}} min<br></span>
    <span *ngIf="routeDurationHikeHours > 0"> {{routeDurationHikeHours}} h {{routeDurationHikeMin}} min<br></span>
  </div>

  <!-- end statistics -->
</div>

<!-- show elevation profile chart -->
<div *ngIf="currentTrack && hasElevationData">

  <!-- title -->
  <div style="margin:0.5rem; margin-top:1.0rem; font-size:x-large;">
    {{'MAPS.TRACK-DET.ELE-PROFILE_TITLE_LBL'| translate}}
  </div>
  <!-- elevation-chart -->
  <div style="margin:0.5rem; margin-right:1.0rem;">
    <app-elevation-profile-chart-component [data]="elevationChartData" [languageCode]="languageCode"
      [chartId]="'elev0-chart'">
    </app-elevation-profile-chart-component>
  </div>

  <!-- end elevation profile chart-->
</div>

<!-- show elevation profile -->
<!-- <div *ngIf="currentTrack">

  <div style="margin:0.5rem; margin-top:1.0rem; font-size:x-large;">Elevation profile</div>
  <div style="margin:0.5rem; margin-top:0.5rem; width:98%; border: 2px solid green">
    <canvas #drawboard style="width:100%"></canvas>
  </div>
</div> -->