import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "chart.js";
import { IScatterData } from "src/app/models/chart-types";


@Component({
    selector: "app-elevation-profile-chart-component",
    templateUrl: "./elevation-profile-chart.component.html",
    styleUrls: ["./elevation-profile-chart.component.scss"]
})
export class ElevationProfileChartComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() data: IScatterData;
    @Input() chartId: string;
    @Input() languageCode: string;

    @Output() pointedIndexChanged: EventEmitter<number> = new EventEmitter();
    @Output() chartClick: EventEmitter<Element> = new EventEmitter();

    public profileChart: Chart;
    public chartTitle: string;
    public scaleXTitle: string;
    public scaleYTitle: string;
    public

    @ViewChild("container") containerElement: ElementRef;


    constructor(
        private translate: TranslateService,
    ) {
        // get texts
        translate.get("MAPS.ELE-CHART.TITLE").subscribe((text: string) => { this.chartTitle = text; });
        translate.get("MAPS.ELE-CHART.SCALE-X_TITLE").subscribe((text: string) => { this.scaleXTitle = text; });
        translate.get("MAPS.ELE-CHART.SCALE-Y_TITLE").subscribe((text: string) => { this.scaleYTitle = text; });
    }

    public ngOnInit() {
        // console.log("ElevationChart:ngOnInit-data", this.data);

    }
    public async ngOnChanges(changes: SimpleChanges) {
        console.log("ElevationChart:ngOnChanges-simpleChanges", changes);
        // console.log("ElevationChart:ngOnChanges-profileChart", this.profileChart);

        if (this.profileChart) {
            this.profileChart.destroy();
        }
        setTimeout(() => {
            const ctx = document.getElementById(this.chartId) as HTMLCanvasElement;
            // console.log("ElevationChart:ngOnChanges-ctx", ctx);
            this.createChart(ctx);
        });

    }

    public ngAfterViewInit() {
        // console.log("ElevationChart:ngAfterViewInit-data", this.data);
        // get drawing context
    }

    private createChart(ctx: any) {
        // create chart
        this.profileChart = new Chart(ctx, {
            type: "scatter",
            data: {
                datasets: [{
                    label: "Elevation-Profile",
                    data: this.data.points,
                    showLine: true,
                    // fill: false,
                    borderWidth: 2,
                    borderColor: "rgba(30,144,255, 1)",
                    // radius: 0,
                    pointStyle: "circle",
                    //backgroundColor: "rgba(30,144,255, 1)",
                    // hoverRadius: 4,
                }]
            },
            options: {
                title: {
                    display: true,
                    // text: "Elevation profile",
                    text: this.chartTitle,
                },
                elements: {
                    point: {
                        // pointStyle: "cross",
                    },
                    line: {
                        tension: 0, // disables bezier curves
                    }
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                legend: {
                    display: false
                },
                scales: {
                    x: {
                        scaleLabel: {
                            display: true,
                            // labelString: "Distance [km]",
                            labelString: this.scaleXTitle,
                        },
                        ticks: {
                        }
                    },
                    y: {
                        scaleLabel: {
                            display: true,
                            // labelString: "Elevation [m]",
                            labelString: this.scaleYTitle,
                        },
                        ticks: {
                        }
                    }
                },
                interaction: {
                    intersect: true,
                    mode: "nearest"
                },
                events: ["mousemove", "mouseout", "touchmove", "touchstart"],
                onHover: (e) => {
                    // console.log("ElevationChart:onHover-event", e);
                    const canvasPos = Chart.helpers.getRelativePosition(e, this.profileChart);
                    // console.log("ElevationChart:onHover-event-canvasPos", canvasPos);
                    // console.log("ElevationChart:onHover-event-profileChart", profileChart);
                    const scaleX = this.profileChart.scales["x-axis-1"];
                    // console.log("ElevationChart:onHover-event-scaleX", scaleX);
                    const dataX = scaleX.getValueForPixel(canvasPos.x);
                    // console.log("ElevationChart:onHover-event-dataX", dataX);
                    const index = this.getPointedIndex(dataX);
                    // console.log("ElevationChart:onHover-event-index", index);
                    this.pointedIndexChanged.emit(index);
                },
            },
            plugins: [{
                id: "myEventCatcher",
                afterEvent: (chart, event, pluginOptions) => {
                    // console.log("ElevationChart:onHover-plugin-event", event);
                    if (event.type === "touchstart") {
                        // process the event
                        console.log("ElevationChart:onHover-plugin-touchstart");
                        this.pointedIndexChanged.emit(-1);
                    }
                    if (event.type === "mouseout") {
                        // process the event
                        // console.log("ElevationChart:onHover-plugin-mouseout");
                        this.pointedIndexChanged.emit(-1);
                    }
                }
            }],
        });
        // console.log("ElevationProfileChart:ngOnChanges-profileChart", profileChart);

    }

    private getPointedIndex(x: number) {
        let nearestP = this.data.points[this.data.points.length - 1];
        // console.log("ElevationChart:onHover-event-nearestP", nearestP);
        for (const p of this.data.points) {
            if (x < p.x) {
                // console.log("ElevationChart:onHover-event-p", p);
                nearestP = p;
                break;
            }
        }
        const nearestIndex = this.data.points.indexOf(nearestP);
        return (nearestIndex);
    }

    public onClick() {
        // console.log("ElevationChart:onClick-isFullscreen");
        // const elem = document.fullscreenElement;
        // if (!elem) { this.openFullscreen(); }
        // if (elem) { this.exitFullscreen(); }
        const elem = this.containerElement.nativeElement;
        this.chartClick.emit(elem);

    }
    private openFullscreen() {
        if (!document.fullscreenEnabled) { return; }
        const elem = this.containerElement.nativeElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }
    private exitFullscreen() {
        document.exitFullscreen();
    }

}
